import {CustomReport, ReportTab as ReportTabInterface} from '@contractool/schema'
import * as React from 'react'
import {createPortal} from 'react-dom'
import RGL, {WidthProvider} from 'react-grid-layout'
import {Route, Switch, useParams} from 'react-router-dom'

import {Context as ReportsContext} from 'contexts/reports'
import {Context as ReportTabContext} from 'contexts/reports/tab'
import {http} from 'utils/http'

import {ReportCreateButton} from 'components/reports/tab'
import ReportCreateOverlay from './ReportCreateOverlay'
import ReportView from './report'

const ReactGridLayout = WidthProvider(RGL)

const PortedReportCreateButton = () => {
  const createButtonPort = document.getElementById('create-button')

  return createButtonPort !== null
    ? createPortal(<ReportCreateButton />, createButtonPort)
    : null
}

const Layout: React.FC = () => {
  const {tab, idx} = React.useContext(ReportTabContext)

  const [tabState, setTabState] = React.useState<ReportTabInterface>(tab)
  React.useEffect(() => {
    setTabState(tab)
  }, [tab])

  const handleReportUpdate = React.useCallback(
    async (updatedConfig: CustomReport, reportIdx: number) => {
      const {
        data: updatedReport
      } = await http.put<CustomReport>(
        `/api/report-tabs/${idx}/${reportIdx}`,
        updatedConfig
      )
      tab.reports[reportIdx] = updatedReport

      return updatedReport
    }, [tab, idx])

  const handleReportDelete = React.useCallback(
    async (reportIdx: number) => {
      await http.delete<ReportTabInterface>(`/api/report-tabs/${idx}/${reportIdx}`)
      tab.reports.splice(reportIdx, 1)
      setTabState({...tab})
    }, [tab, idx])

  const saveGrid = React.useCallback(
    async (grid: any, tabIdx: number) => {
      const {
        data: reports
      } = await http.post<CustomReport[]>(`/api/report-tabs/${tabIdx}/grid`, grid)
      const prevReports = tabState.reports
      prevReports[tabIdx] = {...prevReports[tabIdx], grid: grid[tabIdx]}
      setTabState((prevTabState) => {
        return {
          ...prevTabState,
          reports: prevReports
        }
      })

      return reports
    }, [tabState.reports]
  )
  const handleSaveGrid = React.useCallback(async (grid: any) => {
    // silent update of reports without rerender
    tab.reports = await saveGrid(grid, idx)
  }, [saveGrid, idx, tab])

  return (
    <>
      <PortedReportCreateButton />
      {tabState.reports.length > 0 && (
        <ReactGridLayout
          className="layout"
          rowHeight={70}
          cols={12}
          onDragStop={handleSaveGrid}
          onResizeStop={handleSaveGrid}
          draggableHandle=".drag-handle"
        >
          {tabState.reports.map((config: any, reportIdx: number) => (
            <div key={`report-${idx}-${config.title}`} data-grid={config.grid} style={{...(!config.collapsed ? {zIndex: 1} : null)}}>
              <ReportView
                config={config}
                idx={reportIdx}
                onUpdate={handleReportUpdate}
                onDelete={handleReportDelete}
              />
            </div>
          ))}
        </ReactGridLayout>
      )}
    </>
  )
}

const Routes = () => (
  <>
    <Route path="/reports/:tabIdx">
      <Layout/>
    </Route>
    <Switch>
      <Route path="/reports/:tabIdx/create">
        <ReportCreateOverlay/>
      </Route>
    </Switch>
  </>
)

const Provider: React.FC = ({children}) => {
  const {tabs} = React.useContext(ReportsContext)

  const {tabIdx} = useParams<{tabIdx?: string}>()
  const idx = Number(tabIdx)
  const tab = tabs[idx]

  const value = React.useMemo(() => ({
    tab,
    idx
  }), [
    tab,
    idx
  ])

  return (
    <ReportTabContext.Provider value={value}>
      {children}
    </ReportTabContext.Provider>
  )
}

const View = () => (
  <Provider>
    <Routes/>
  </Provider>
)

export default View
