import * as React from 'react'

import {FormContext} from 'components/Form'
import {Option} from 'components/Dropdown'
import ProjectContext from 'views/projects/ProjectContext'
import {FieldShowCondition} from '../../../../../_common/schema/FieldShowCondition'

const fieldDependsOnOtherThan = (Component: React.ComponentType<any>) =>
  (props: any) => {
    let {field} = props
    if (!field) {
      field = props.role
    }
    const formContext = React.useContext(FormContext)
    const projectContext = React.useContext(ProjectContext)

    let show = true
    if (field.showOnlyIf) {
      field.showOnlyIf.forEach((condition: FieldShowCondition) => {
        if (!matchCondition(condition, formContext, projectContext)) {
          show = false
        }
      })

      if (!show) {
        return null
      }
    }

    if (field.filterOptions) {

      let relatedValue = formContext.values.fields[field.filterOptions.propertyName]

      if (typeof relatedValue === 'undefined') {
        relatedValue = formContext.values[field.filterOptions.propertyName]
      }

      if (relatedValue === true) {
        relatedValue = '1'
      }
      if (relatedValue === false) {
        relatedValue = '0'
      }
      if ('' + relatedValue in field.filterOptions.conditions) {
        field.values = field.filterOptions.conditions[relatedValue] as Option<string>[]
      } else {
        field.values = []
      }
    }

    return <Component {...props} />
  }

const matchCondition = (condition: FieldShowCondition, formContext: any, projectContext: any) => {
  let relatedValue = formContext.values[condition.propertyName]
  if (typeof relatedValue === 'undefined' && typeof formContext.values.fields !== 'undefined') {
    relatedValue = formContext.values.fields[condition.propertyName]
  }

  if (projectContext.resource !== null) {
    if (typeof relatedValue === 'undefined' && typeof projectContext.resource.fields !== 'undefined') {
      relatedValue = projectContext.resource.fields[condition.propertyName]
    }

    if (condition.propertyName === 'category_id') {
      relatedValue = projectContext.resource.category_id
    }
  }

  if (relatedValue === true) {
    relatedValue = '1'
  }
  if (relatedValue === false) {
    relatedValue = '0'
  }
  if (!condition.value.includes('' + relatedValue)) {
    return false
  }

  return true
}



export default fieldDependsOnOtherThan
