import React, {FC} from 'react'
import {Project} from '../../../../../_common/schema'
import {Confirmation} from '../../../components/Confirmation'
import {MenuItem} from '../../../components/Menu'
import {translate} from 'utils/translations'

export const Clone: FC<{project: Project}> = ({project}) => {
  return (
    <Confirmation
      onConfirm={() => {
        window.location.href = `/#/projects/new/form?clone=${project.id}`
      }}
      trigger={({onClick}) => (
        <MenuItem
          icon="duplicate"
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
        >
          {translate('Clone project')}
        </MenuItem>
      )}
      heading={`${translate('Clone project')}?`}
      buttonText={translate('Yes, clone')}
      color="yellow"
    >
      {translate('Are you sure you want to clone this project?')}
    </Confirmation>
  )
}
