import {UserGroup, Role, User, Project} from '@contractool/schema'
import React, {FC, useContext, useCallback, useMemo} from 'react'
import {Route, Switch, useHistory, useParams} from 'react-router-dom'

import {Form} from 'components/Form'
import {Modal} from 'components/Modal'
import {Button} from 'components/Button'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {Page} from 'components/Page'
import {fromQueryString} from 'utils/url'
import {Form as DellSWPForm} from 'integrations/dell_swp/views/project/components'
import {Form as ScantraxxForm} from 'integrations/scantraxx/components/views/project/components'
import {Form as DellJIForm} from 'integrations/dell_ji/components/views/project/components'
import {DellForm} from './DellForm'
import {DefaultForm} from './DefaultForm'
import {EventEmitter} from 'utils/eventEmitter'
import ProjectFormProvider from 'views/project/ProjectFormProvider'
import {useLocalStorage} from '@rehooks/local-storage'

export function NewProjectForm() {
  const history = useHistory()

  const close = useCallback(() => {
    history.push(`/projects/new/form${history.location.search}`)
  }, [history])
  const {success} = useToasts()

  const searchParams = fromQueryString(history.location.search)

  let url = `api/projects/new${history.location.search}`
  if (searchParams && searchParams.clone !== undefined) {
    url = `api/projects/${searchParams.clone}`
  }
  const [initialProject] = useRequest<Project | undefined>(url, undefined)

  const handleSubmit = useCallback((values) => http.post<Project>('/api/projects', values), [])

  const handleUserModalClose = useCallback(() => {
    EventEmitter.dispatch('api/users.invalidated', [])
    close()
  }, [close])
  const [, setCreatedPopup] = useLocalStorage('_createdPopup')

  return (
    <>
      <Switch>
        <Route
          path={'/projects/new/form/new-user/:role'}
          render={() => <AddUserModal onClose={handleUserModalClose} />}
        />
      </Switch>
      <Page heading={translate('New Project Request')}>
        {initialProject ? (
          <Form
            name="new-project"
            initialValues={{
              ...initialProject
            }}
            onSuccess={(data) => {
              success(`${translate('Project was created')}.`)
              if (data.created_popup) {
                setCreatedPopup(data.created_popup)
              }
              history.push('/projects/' + data.id)
            }}
            onSubmit={handleSubmit}
            guard={false}
            loader={'big'}
          >
            <FormContainer initialProject={initialProject} />
            <Form.Submit>{translate('Create Project')}</Form.Submit>
          </Form>
        ) : null}
      </Page>
    </>
  )
}

const FormContainer: FC<{initialProject: Project}> = ({initialProject}) => {
  const history = useHistory()
  const {config} = useContext(AppContext)

  return config.integration === 'dell' ? (
    <ProjectFormProvider>
      <DellForm search={history.location.search} />
    </ProjectFormProvider>
  ) : config.integration === 'dell_ji' ? (
    <ProjectFormProvider create={true}>
      <DellJIForm project={initialProject} />
    </ProjectFormProvider>
  ) : config.integration === 'dell_swp' ? (
    <ProjectFormProvider create={true}>
      <DellSWPForm project={initialProject} />
    </ProjectFormProvider>
  ) : config.integration === 'scantraxx' ? (
    <ProjectFormProvider create={true}>
      <ScantraxxForm project={initialProject} />
    </ProjectFormProvider>
  ) : (
    <ProjectFormProvider create={true}>
      <DefaultForm project={initialProject} />
    </ProjectFormProvider>
  )
}

export const AddUserModal: FC<{onClose: () => void}> = ({onClose}) => {
  const {role: roleKey} = useParams<{role?: string}>()
  const {config} = useContext(AppContext)
  const role = config.roles.find(({key}: Role) => key === roleKey)

  const initialValues = useMemo(() => ({
    name: '',
    email: '',
    timezone: 'UTC',
    roles: [role],
    groups: role.groups.map((groupKey: string) => config.user_groups.find((group: UserGroup) => group.key === groupKey))
  }), [role, config.user_groups])
  const {success} = useToasts()

  return (
    <Modal heading={translate('Add new user')} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={(values) => http.post<User>('api/users/from-form', values)}
        onSuccess={() => {
          success(translate('User was successfully created'))
          onClose()
        }}
      >
        <Form.TextInput
          name="name"
          label={translate('Name')}
          placeholder={translate('Name')}
        />
        <Form.TextInput name="email" label="Email" placeholder="Email" className="mt-6" />
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onClose}>
            {translate('Cancel')}
          </Button>
          <Form.Submit>{translate('Save Changes')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
