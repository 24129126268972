import Axios from 'axios'
import omitBy from 'lodash/omitBy'
import {FileRequest} from '@contractool/schema'

export async function upload(
  file: File,
  {onUploadProgress = () => {}}: {onUploadProgress?: (progress: number) => void} = {}
): Promise<{path: string; name: string}> {
  const response = await Axios.create()
    .post<FileRequest>(
      '/api/files/request',
      {
        bucket: '',
        content_type: file.type,
        expires: '',
        visibility: ''
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Application-Release' : localStorage.getItem('_app_version')
        }
      }
    )
    .then((response) => response.data)

  if (response.local) {
    await Axios.create().post(response.url, formData({file}), {
      headers: response.headers,
      onUploadProgress: (e) => {
        onUploadProgress(Math.floor((e.loaded / e.total) * 100))
      }
    })
  } else {
    await Axios.create().put(response.url, file, {
      headers: omitBy(response.headers, (_value, key) => key === 'Host'),
      onUploadProgress: (e) => {
        onUploadProgress(Math.floor((e.loaded / e.total) * 100))
      }
    })
  }

  return {path: response.key, name: file.name}
}

function formData(input: Record<string, any>): FormData {
  const data = new FormData()

  Object.entries(input).forEach(([key, value]) => {
    data.append(key, value)
  })

  return data
}
