import React, {FC, useContext, useState, FormEvent, ReactElement} from 'react'
import {Project, LogFull, User, Comment} from '@contractool/schema'
import {MainPanel} from 'components'
import {Icon} from 'components/Icon'
import {Avatar} from 'components/Avatar'
import {Tabs, Tab} from 'components/Tabs'
import {useToasts} from 'hooks'
import {CommentForm} from 'components/CommentForm'
import {AppContext, GuardContext} from 'contexts'
import {http, Response} from 'utils/http'
import {BasicLog} from './ProjectLogs/BasicLog'
import {ConversationLog} from './ProjectLogs/ConversationLog'
import {DocumentLog} from './ProjectLogs/DocumentLog'
import {StateLog} from './ProjectLogs/StateLog'
import {TeamLog} from './ProjectLogs/TeamLog'
import {MilestonesLogs} from './ProjectLogTabs/MilestonesLogs'
import {MilestoneLog} from './ProjectLogs/MilestoneLog'
import {MilestonesLog} from './ProjectLogs/MilestonesLog'
import {EventEmitter} from 'utils/eventEmitter'
import ProjectUpdateLog from './ProjectLogs/ProjectUpdateLog'
import {MilestonesEditLog} from './ProjectLogs/MilestonesEditLog'
import OldMilestonesModal from './OldMilestonesModal'
import useToggle from 'utils/hooks/useToggle'
import {permissionRequest} from 'utils/wildcard'
import {translate} from 'utils/translations'
import moment from 'moment-timezone'
import {useSmallLoader} from 'components/Loader'
import {ApprovalLog} from './ProjectLogs/ApprovalLog'
import {EmailLog} from './ProjectLogs/EmailLog'

export const ActivityPanel: FC<{ project: Project; onUpdate: () => void }> = ({
  project,
  onUpdate
}) => {
  const [showCommentForm, setShowCommentForm] = useState(false)
  const [tabSelected, setTabSelected] = useState('all-activities')
  const {config} = useContext(AppContext)
  const convoLength = project.logs_full.filter(
    (log: { meta: { type: string } }) => log.meta.type === 'COMMENT_CREATED'
  ).length

  const documentLength = project.logs_full.filter((log: { meta: { type: string | string[] } }) =>
    log.meta.type.includes('ATTACHMENT')
  ).length

  let permissions = project.can

  return (
    <MainPanel
      heading={translate('Activity')}
      right={
        permissionRequest(permissions, 'project.widget.log.edit') ? (
          <div
            className="flex items-center text-blue-700 cursor-pointer hover:text-blue-400"
            onClick={() => {
              console.log('New message tabSelected', tabSelected)
              if (tabSelected === 'conversations') {
                setShowCommentForm(!showCommentForm)
              } else {
                setShowCommentForm(true)
              }
              EventEmitter.dispatch('tab-select-project-logs', 'conversations')
              setTabSelected('conversations')
            }}
          >
            <Icon name="add" size={5}/>
            <span className="ml-2">{translate('New message')}</span>
          </div>
        ) : null
      }
    >
      <Tabs
        id="project-logs"
        onSelect={(tabClicked) => {
          setTabSelected(tabClicked)
          if (tabClicked !== 'conversations') {
            setShowCommentForm(false)
          }
        }}
      >
        <Tab name="all-activities" heading={translate('All')} className="pt-4">
          <ActivityLogs
            project={project}
            onUpdate={onUpdate}
            showCommentForm={showCommentForm}
            hide={() => setShowCommentForm(false)}
          />
        </Tab>
        <Tab
          name="conversations"
          heading={
            convoLength > 0
              ? convoLength + ` ${translate('Conversations')}`
              : translate('Conversation')
          }
          className="pt-4"
        >
          <ActivityLogs
            project={project}
            onUpdate={onUpdate}
            tabType="CONVERSATION"
            showCommentForm={showCommentForm}
            hide={() => setShowCommentForm(false)}
          />
        </Tab>
        <Tab
          name="documents"
          heading={
            documentLength > 0
              ? documentLength + ` ${translate('Document')}`
              : translate('Document')
          }
          className="pt-4"
        >
          <ActivityLogs
            project={project}
            onUpdate={onUpdate}
            tabType="DOCUMENT"
            showCommentForm={showCommentForm}
            hide={() => setShowCommentForm(false)}
          />
        </Tab>
        <Tab name="states" heading={translate('States')} className="pt-4">
          <ActivityLogs
            project={project}
            onUpdate={onUpdate}
            tabType="STATE"
            showCommentForm={showCommentForm}
            hide={() => setShowCommentForm(false)}
          />
        </Tab>
        <Tab name="emails" heading={translate('Emails')} className="pt-4">
          <ActivityLogs
            project={project}
            onUpdate={onUpdate}
            tabType="EMAILS"
            showCommentForm={showCommentForm}
            hide={() => setShowCommentForm(false)}
          />
        </Tab>
        {permissionRequest(permissions, 'project.widget.private_conversation.view') ? (
          <Tab
            name="private_conversation"
            heading={translate(config.integration === 'dell_swp' ? 'Notes' : 'Private Conversation')}
            className="pt-4"
          >
            <ActivityLogs
              project={project}
              onUpdate={onUpdate}
              tabType="PRIVATE_CONVERSATION"
              showCommentForm={showCommentForm}
              hide={() => setShowCommentForm(false)}
            />
          </Tab>
        ) : (
          <></>
        )}
        {permissionRequest(permissions, 'project.widget.milestone.view') ? (
          <Tab name="milestones" heading={translate('Milestones')} className="pt-4">
            <MilestonesLogs project={project}/>
          </Tab>
        ) : (
          <></>
        )}
      </Tabs>
    </MainPanel>
  )
}

const ActivityLogs: FC<{
    project: Project;
    tabType?: 'STATE' | 'CONVERSATION' | 'DOCUMENT' | 'PRIVATE_CONVERSATION' | 'EMAILS' | undefined;
    onUpdate: () => void;
    showCommentForm: boolean;
    hide: () => void;
}> = ({project, tabType, onUpdate, showCommentForm, hide}) => {
  const onSendFile = (file: {path: string; name: string}) => {
    const response = http.post<Document>(project.attachments_url, {file})
    response.then(() => onUpdate() )

    return response
  }

  let filterLogs
  switch (tabType) {
    case 'STATE':
      filterLogs = project.logs_full.filter(
        (log: { meta: { type: string } }) => log.meta.type === 'PROJECT_STATE_UPDATED'
      )
      break
    case 'CONVERSATION':
      filterLogs = project.logs_full.filter(
        (log: { meta: { type: string } }) => log.meta.type === 'COMMENT_CREATED'
      )
      break
    case 'PRIVATE_CONVERSATION':
      filterLogs = project.logs_full.filter(
        (log: { meta: { type: string } }) => log.meta.type === 'PRIVATE_COMMENT_CREATED'
      )
      break
    case 'DOCUMENT':
      filterLogs = project.logs_full.filter((log: { meta: { type: string | string[] } }) =>
        log.meta.type.includes('ATTACHMENT')
      )
      break
    case 'EMAILS':
      filterLogs = project.logs_full.filter((log: { meta: { type: string | string[] } }) =>
        log.meta.type.includes('EMAIL_SENT')
      )
      break
    default:
      filterLogs = [...project.logs_full]
      break
  }

  filterLogs = filterLogs.map((log: LogFull, index) => {
    let simpleMessages = {
      'TASK_CREATED': 'created a task',
      'TASK_UPDATED': 'updated a task',
      'TASK_OPENED': 'opened a task',
      'TASK_RESOLVED': 'completed a task',
      'PROJECT_CREATED': 'created a project',
      'PROJECT_IMPORTED': 'imported a project'
    } as { [key: string]: any }

    for (let type in simpleMessages) {
      if (type === log.meta.type) {
        return (
          <BasicLog
            key={type + '-activity-log' + log.id + index}
            log={log}
            desc={translate(simpleMessages[type])}
          />
        )
      }
    }

    switch (log.meta.type) {
      default:
        return <BasicLog key={'basic-activity-log' + log.id + index} log={log} desc=""/>
      case 'APPROVAL_CREATED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('created an approval')} 
            project={project}
          />
        )
      case 'APPROVAL_UPDATED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('updated the approval')} 
            project={project}
          />
        )
      case 'APPROVAL_OPENED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('opened the approval')} 
            project={project}
          />
        )
      case 'APPROVAL_APPROVED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('approved the approval')} 
            project={project}
          />
        )
      case 'APPROVAL_DENIED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('denied the approval')} 
            project={project}
          />
        )
      case 'APPROVAL_CANCELED': 
        return (
          <ApprovalLog 
            key={'apprcreate-activity-log' + log.id + index}
            log={log}
            desc={translate('canceled the approval')} 
            project={project}
          />
        )
      case 'PRIVATE_COMMENT_CREATED':
      case 'COMMENT_CREATED':
        return (
          <ConversationLog
            key={'conv-activity-log' + log.id + index}
            log={log}
            onUpdate={onUpdate}
            onSendFile={onSendFile}
          />
        )
      case 'PROJECT_UPDATED':
        return (
          <ProjectUpdateLog
            key={'projupdate-activity-log' + log.id + index}
            log={log}
            desc={translate('updated a project')}
          />
        )
      case 'PROJECT_STATE_UPDATED':
        return (
          <StateLog
            key={'projstateupdate-activity-log' + log.id + index}
            log={log}
            desc={translate('updated a project state')}
          />
        )
      case 'ATTACHMENT_ADDED':
        return (
          <DocumentLog
            key={'attachadd-activity-log' + log.id + index}
            log={log}
            desc={translate('added a document')}
          />
        )
      case 'ATTACHMENT_UPDATED':
        return (
          <DocumentLog
            key={'attachupdate-activity-log' + log.id + index}
            log={log}
            desc={translate('updated a document')}
          />
        )
      case 'ATTACHMENT_REMOVED':
        return (
          <DocumentLog
            key={'attachrem-activity-log' + log.id + index}
            log={log}
            desc={translate('removed a document')}
          />
        )
      case 'ATTACHMENT_REPLACED':
        return (
          <DocumentLog
            key={'attachrepl-activity-log' + log.id + index}
            log={log}
            desc={translate('replaced a document')}
          />
        )
      case 'TEAM_MEMBER_ADDED':
        return (
          <TeamLog
            key={'teammemadd-activity-log' + log.id + index}
            log={log}
            desc={translate('added an user')}
          />
        )
      case 'TEAM_MEMBER_REPLACED':
        return (
          <TeamLog
            key={'teammemrepl-activity-log' + log.id + index}
            log={log}
            desc={translate('replaced an user')}
          />
        )
      case 'TEAM_MEMBER_REMOVED':
        return (
          <TeamLog
            key={'teammemrem-activity-log' + log.id + index}
            log={log}
            desc={translate('removed an user')}
          />
        )

      case 'MILESTONE':
        return <MilestoneLog key={`milestone-taball-${log.id}${index}`} log={log}/>
      case 'MILESTONE_NEW':
      case 'MILESTONE_DELETED':
        return <MilestonesEditLog key={`milestone-taball-${log.id}${index}`} log={log}/>
      case 'MILESTONES':
        return <MilestonesLog key={`milestone-taball-${log.id}${index}`} log={log}/>
      case 'EMAIL_SENT':
        return (
          <EmailLog
            key={'teammemrem-activity-log' + log.id + index}
            log={log}
            desc={translate('received an email')}
          />
        )
    }
  })

  const privateConvPermission = permissionRequest(
    project.can,
    'project.widget.private_conversation.edit'
  )

  return (
    <>
      {showCommentForm && (
        <NewMessage
          project={project}
          onUpdate={onUpdate}
          onClose={hide}
          type="COMMENT_CREATED"
          onSendFile={onSendFile}
        />
      )}
      {tabType === 'PRIVATE_CONVERSATION' && privateConvPermission && (
        <NewMessage
          project={project}
          onUpdate={onUpdate}
          onClose={hide}
          type="PRIVATE_COMMENT_CREATED"
          onSendFile={onSendFile}
        />
      )}
      {filterLogs.length ? (
        filterLogs
      ) : (
        <div className="py-4">
          <div
            className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
            <Icon name="list" size={5}/>
          </div>

          <div className="text-gray-600 text-center pt-6">
            {translate('There is no activity yet...')}
          </div>
        </div>
      )}
    </>
  )
}

export const ActivityContainer: FC<{
    children: ReactElement | ReactElement[];
    className?: string;
}> = ({children, className}) => (
  <div className={'bg-white p-6 rounded-lg mt-2 mb-4 ' + className}>{children}</div>
)

export const ActivityBody: FC<{ children: ReactElement | ReactElement[]; className?: string }> = ({
  children,
  className
}) => <div
  className={'ml-14 mt-3 ' + className}>{children}</div>

export const ActivityHeader: FC<{
    causer: User | null | undefined;
    desc?: string;
    created_at?: string;
    timezone?: string;
    old?: any;
}> = ({causer, desc, created_at, timezone, old: milestones}) => {
  const [modalVisible, modalVisibleToggle] = useToggle(false)
  const {config} = useContext(AppContext)
  let userOffsetTime = moment()
  if (created_at && timezone) {
    const commentTime = moment.tz(created_at, timezone)
    userOffsetTime = commentTime.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  return (
    <div className="flex justify-between items-center text-sm">
      <div className="flex items-center">
        {causer && <Avatar user={causer} className="w-10 h-10 mr-4"/>}
        <div>
          {causer && causer.name}
          {desc && <span className="text-gray-600">{' ' + desc}</span>}
        </div>
      </div>
      {milestones && (
        <div className="cursor-pointer" onClick={modalVisibleToggle.on}>
          {translate('Show old milestones')}
        </div>
      )}
      {created_at && timezone && (
        <div className="text-gray-600">{userOffsetTime.format(config.date_format.date_time)}</div>
      )}
      {modalVisible && (
        <OldMilestonesModal milestones={milestones} onClose={modalVisibleToggle.off}/>
      )}
    </div>
  )
}

const NewMessage: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    type: string;
    onSendFile:(file: {path: string; name: string}) => Promise<Response<any>>;
}> = ({project, onUpdate, onClose, type, onSendFile}) => {
  const {user} = useContext(GuardContext)
  const smallLoader = useSmallLoader()
  const [comment, setComment] = useState('')
  const {success} = useToasts()

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    smallLoader.start(translate('Sending the message...'))
    http.post<Comment>(project.comments_url, {body: comment, type: type}).then((response) => {
      onClose()
      onUpdate()
      success(
        `${translate('Comment was successfully added by')} ${
          response.data.commenter.name
        }.`
      )
      setComment('')
      smallLoader.stop()
    })
  }

  return (
    <ActivityContainer className="transition duration-150 ease-linear shadow-2xl">
      <ActivityHeader causer={user}/>
      <CommentForm submitHandler={submit} setComment={setComment} comment={comment} onSendFile={onSendFile} />
    </ActivityContainer>
  )
}
