import * as React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {GuardContext} from 'contexts'
import {usePrevious} from 'hooks'
import routeListWithPermissionRestrictions from './routeListWithPermissionRestrictions'
import {checkAuthorization} from './helpers'

const AuthorizationGuard = ({children}: {children?: React.ReactNode}) => {
  const {user} = React.useContext(GuardContext)
  const location = useLocation()
  const prevPath = usePrevious(location.pathname)

  let authorized = checkAuthorization(location.pathname, user.permissions, routeListWithPermissionRestrictions)

  if (location.pathname.startsWith('/ui')) {
    authorized = (process.env.NODE_ENV !== 'production' || !!process.env.REACT_APP_CI)
  }

  return authorized ? (
    <>
      {children}
    </>
  ) : <Redirect to={prevPath || '/'} />
}

export default AuthorizationGuard
