import {CustomReport} from '@contractool/schema'
import {createContext} from 'react'

export const Context = createContext<{
    config: CustomReport
    update: (updatedConfig: CustomReport) => void
    remove: () => void
    idx: number
      }>({
        config: null as unknown as CustomReport,
        update: () => {},
        remove: () => {},
        idx: null as unknown as number
      })
