import {FieldTemplate, Project, TeamMember} from '@contractool/schema'
import React, {FC, ReactNode, useContext, useState} from 'react'
import {useParams, useHistory} from 'react-router'

import FieldAtTop from 'components/FieldAtTop'
import {AppContext} from 'contexts'
import {useRequest} from 'hooks/useRequest'
import {translate} from 'utils/translations'
import {permissionRequest} from 'utils/wildcard'
import {ApprovalsPanel} from 'views/projects/Approvals/ApprovalsPanel'
import {Tutorial} from 'views/projects/Widgets/Tutorial'
import {TaskPanel} from './TaskPanel'
import {ProjectDetailsPanel} from './ProjectDetailsPanel'
import {ContractPanel} from './ContractPanel'
import {DocumentsPanel} from './DocumentsPanel'
import {ProjectActions} from './ProjectActions'
import {ProgressPanel} from './ProgressPanel'
import {ActivityPanel} from './ActivityPanel'
import {ProjectTeam} from './ProjectTeam'
import {Milestones} from './Widgets/Milestone'
import {Esign} from './Widgets/Esign'
import {WetSign} from './Widgets/WetSign'
import {Hierarchy} from './Widgets/Hierarchy'
import {RelatedProjects} from './Widgets/RelatedProjects'
import {Workload} from './Widgets/Workload'
import Group from './Widgets/Group'
import ProjectContext from './ProjectContext'
import {ProjectLabels} from 'views/projects/ProjectLabels'
import {ProjectCreatedPopup} from 'views/ProjectCreatedPopup'

export function ProjectDetail() {
  const {id} = useParams<{id?: string}>()
  const [project, {refresh}] = useRequest<Project | undefined>(
    `/api/projects/${id}`,
    undefined,
    undefined,
    () => {setProjectLoading(false)}
  )
  const history = useHistory()
  const [projectLoading, setProjectLoading] = useState<boolean>(false)
  const {config} = useContext(AppContext)
  const topProjectFields = config.fields
    ? config.fields.filter((f: FieldTemplate) => f.workflow === project?.workflow && f.isAtTop)
    : []

  const projectContext = React.useMemo(
    () => ({
      resource: project as Project,
      teamMembers: project?.team.memberships.filter(
        (member: TeamMember) => member.role !== 'assignee' && member.role !== 'requestor' && !member.deleted
      ) ?? [],
      refresh
    }),
    [project, refresh]
  )
  if (!project) return null
  const close = () => {
    history.push(`/projects/${project.id}`)
  }

  const CancelTag: FC<{project: Project}> = ({project}) => {
    return ['denied', 'canceled', 'onhold'].some((item) => project.state.key.includes(item)) ? (
      <div
        className="px-2 py-1 self-center bg-red-100 text-red-700 rounded-lg"
        data-cy="canceled-tag"
      >
        {translate(project.state.label)}
      </div>
    ) : null
  }

  return (
    <ProjectContext.Provider value={projectContext}>
      <div className="flex flex-col h-full">
        <ProjectCreatedPopup/>
        <div className="p-16 border-b border-gray-200" data-cy-section="header">
          <div className="mb-6 flex justify-between">
            <div className="flex">
              <ProjectLabels project={project} />
              <ProjectActions project={project} onRefresh={refresh} />
            </div>
            <ProjectTeam onUpdate={refresh} onClose={close} />
          </div>
          <div className="flex">
            <h1 className="text-xl mr-4">{project.title}</h1>
            <CancelTag project={project} />
          </div>
          <ProgressPanel
            project={project}
            onRefresh={() => {
              refresh()
            }}
            projectLoading={projectLoading}
            setProjectLoading={setProjectLoading}
          />
        </div>
        {project.extra.sign_url && (
          <div className="mb-8" style={{height: '800px'}}>
            <iframe
              title={project.title}
              src={project.extra.sign_url}
              width="100%"
              height="100%"
              style={{
                border: 0,
                overflow: 'hidden',
                minHeight: '600px',
                minWidth: '600px'
              }}
            />
          </div>
        )}
        {project.state.extra.components?.includes('milestone') &&
      permissionRequest(project.can, 'project.widget.milestone.view') && (
            <div className="p-10 border-b border-gray-200 bg-gray-000">
              <Milestones project={project} onUpdate={refresh} />
            </div>
          )}
        {project.state.extra.components?.includes('tutorial') && <Tutorial project={project} />}
        {project.state.extra.components?.includes('workload') && (
          <div className="p-10 border-b border-gray-200 ">
            <Workload project={project}/>
          </div>
        )}
        <div className="flex flex-col-reverse md:flex-row md:flex-1 ">
          <div className="w-full md:w-7/12 p-10 pt-8 bg-gray-000">
            {project.state.extra.components?.includes('log') && (
              <ActivityPanel project={project} onUpdate={refresh} />
            )}
          </div>
          <div className="w-full md:w-5/12 border-l border-gray-200">
            {topProjectFields.map((field: FieldTemplate) => <FieldAtTop key={field.name} field={field} project={project} />)}
            {project.state.extra.components?.includes('contract') && (
              <ContractPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {project.state.extra.components?.includes('documents') && <>
              <DocumentsPanel
                heading={translate('Attachments')}
                fileAddLabel={translate('Add files')}
                project={project}
                onUpdate={refresh}
                onClose={close}
              />
              {/*<DocumentsPanel*/}
              {/*  heading="NDA"*/}
              {/*  fileAddLabel="Add NDA File"*/}
              {/*  type="NDA"*/}
              {/*  project={project}*/}
              {/*  onUpdate={refresh}*/}
              {/*  onClose={close}*/}
              {/*/>*/}
              {/*< DocumentsPanel*/}
              {/*  heading='Letter of Intent'*/}
              {/*  fileAddLabel='Add LOI File'*/}
              {/*  type='LOI'*/}
              {/*  project={project}*/}
              {/*  onUpdate={refresh}*/}
              {/*  onClose={close}*/}
              {/*/>*/}
            </>}
            {project.signatures?.length > 0 && (
              <Esign project={project} />
            )}
            {project.state.extra.components?.includes('wet_sign') && (
              <WetSign project={project} />
            )}
            {project.fields.rfp === true && (
              <DocumentsPanel
                heading={translate(':type Files', {type: 'RFP'})}
                fileAddLabel={translate('Add :type file', {type: 'RFP'})}
                type="RFP"
                project={project}
                onUpdate={refresh}
                onClose={close}
              />
            )}
            {project.fields.competitive_bidding === 'Yes' && (
              <DocumentsPanel
                heading="Bid Files"
                fileAddLabel="Add Bid Files"
                type="RFP"
                project={project}
                onUpdate={refresh}
                onClose={close}
              />
            )}
            {project.state.extra.components?.includes('tasks') && (
              <TaskPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {project.state.extra.components?.includes('approvals') && (
              <ApprovalsPanel project={project} onUpdate={refresh} onClose={close} />
            )}

            {(project.children.length > 0 || project.parent) && (
              <RelatedProjects project={project} />
            )}

            {project.state.extra.components?.includes('details') && (
              <ProjectDetailsPanel project={project} onUpdate={refresh} onClose={close} />
            )}
            {project && (
              <ProjectContext.Provider value={projectContext}>
                <Group />
              </ProjectContext.Provider>
            )}
            {/*{project.state.extra.components?.includes('hierarchy') && (*/}
            {/*  <Hierarchy project={project} />*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </ProjectContext.Provider>
  )
}

export const SidePanel: FC<{heading: ReactNode; right?: ReactNode; className?: string}> = ({
  heading,
  right,
  className,
  children
}) => {
  return (
    <div
      className={className ? className : 'p-8 border-b border-gray-200'}
      data-cy-section={heading}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg">{heading}</div>
        {right}
      </div>
      {children}
    </div>
  )
}
