import React, {FC, useContext} from 'react'

import {Icon} from 'components/Icon'
import {CruncherListModal} from 'components/Search/components'
import {AppContext} from 'contexts'
import useToggle from 'utils/hooks/useToggle'

export const Cruncher: FC<{phrase: string}> = ({
  phrase
}) => {
  const {config} = useContext(AppContext)

  const [cruncherModalVisible, cruncherModalHandler] = useToggle(false)
  const cruncher = () => {
    cruncherModalHandler.on()
  }

  const enabled = config && config.config && config.config.cruncher

  return (
    <>
      {enabled && (
        <div className="w-7">
          <div className="w-7 h-14 flex items-center justify-center cursor-pointer" onClick={cruncher}>
            <Icon name="file-alt" className="text-gray-500" size={6}/>
          </div>
          {cruncherModalVisible && (
            <CruncherListModal
              phrase={phrase}
              onClose={cruncherModalHandler.off}
            />
          )}
        </div>
      )}
    </>
  )
}
