import * as React from 'react'
import {Icon} from 'components/Icon'
import {translate} from 'utils/translations'
import {useDropzone} from 'react-dropzone'
import {upload} from 'utils/upload'
import {http} from 'utils/http'
import {Document, FieldTemplate, File as FileInterface} from '../../../../_common/schema'
import {useUploadingReducer} from 'components/Avatar'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {EventEmitter} from 'utils/eventEmitter'
import {useToasts} from 'hooks'

const FileUpload: React.FC<{field: FieldTemplate, formValues: any}> = ({field,formValues}) => {
  const [{uploading, progress}, dispatch] = useUploadingReducer()
  const {success} = useToasts()
  const startUpload = (file: File) => {
    dispatch({type: 'start_uploading'})
    upload(file, {
      onUploadProgress: (progress) => {
        dispatch({type: 'progress_uploading', progress})
      }
    }).then((response) => {
      http.post<Document>('/api/form/upload', {
        file: response,
        extra: field.extra
      }).then((fileModel: any) => {
        dispatch({type: 'finish_uploading'})
        if (!formValues[field.name]) {
          formValues[field.name] = []
        }
        formValues[field.name].push(fileModel)
        EventEmitter.dispatch('new-project.set-form-formValues', formValues)
        success(translate('File has been uploaded'))
      })
    })
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (files) => {
      files.forEach((file) => startUpload(file))
    }
  })

  return (
    <>
      {uploading && (
        <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
          <CircularProgressbar
            value={progress}
            className="w-6 h-6"
            strokeWidth={5}
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: '#2979ff',
              trailColor: '#e9ecef'
            })}
          />
        </div>
      )}
      <div className={'focus:outline-none'} {...getRootProps()} onClick={() => {
      }}>
        <input type="file" className="hidden" {...getInputProps()} />

        <div className="bg-gray-000 text-gray-600 p-7 rounded-xl">
          <div className="flex items-center justify-center">
            <Icon name="cloud_upload" size={6}/>
            <div className="pl-3">
              {translate('Drop file here or')}{' '}
              <span
                className="text-blue-700 cursor-pointer focus:outline-none"
                {...getRootProps({props: 'onClick'})}
              >
                {translate('browse it')}
              </span>
            </div>
          </div>
          {formValues[field.name] && <div>
            {formValues[field.name].map((file:FileInterface, index:number) => {
              //TODO react. Why this appears only after lostfocus?
              return <div className="mt-2" key={field.name + file.filename + index}>{file.filename}</div>
            })}
          </div>}
        </div>
      </div>
    </>
  )
}

export default FileUpload
