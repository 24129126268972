import {User} from '@contractool/schema'
import {createContext} from 'react'

const GuardContext = createContext<{
    user: User;
}>({
  user: {} as User
})

export default GuardContext
