import {Icons} from 'components/Icon'
import {Color} from 'components/IconCircle'
import {BigRadioBox, RadioBoxContainer, SmallRadioBox} from 'components/RadioBox'
import {Tab, FormTabs} from 'components/Tabs'
import {useToasts} from 'hooks'
import React, {useState} from 'react'

interface Data {
    icon?: Icons;
    title: string;
    desc?: string;
    span?: number;
    color?: Color;
}
interface ExampleData {
    name: string;
    label?: string;
    type: 'BOXES' | 'LIST';
    data: Data[];
}

const exampleData: ExampleData[] = [
  {
    name: 'new-project',
    label: 'New Project',
    type: 'BOXES',
    data: [
      {
        icon: 'add_to_photos',
        title: 'New project',
        desc: 'Contract like MRA, MSA, ISA, SLA, PPDA or MPA',
        span: 2
      },
      {
        icon: 'add_to_photos',
        title: 'Amendment',
        desc: 'Simple Non-disclosure agreement contract',
        span: undefined
      },
      {
        icon: 'remove_circle',
        title: 'Termination',
        desc: 'Contracts that doesn’t fit into any previous category',
        span: undefined
      }
    ]
  },
  {
    name: 'organization',
    label: 'Organization',
    type: 'BOXES',
    data: [
      {
        icon: undefined,
        title: 'Production',
        desc: 'Categories like Materials, OEM Software, Packaging, Hard-drives...',
        span: undefined
      },

      {
        icon: undefined,
        title: 'Logistics',
        desc:
                    'Categories like Marcom, Business Process Outsourcing, Customer Facing Services...',
        span: undefined
      },

      {
        icon: undefined,
        title: 'Indirect',
        desc: 'Categories like Inbound, Outbound, Trade Compliance, Contract Logistics...',
        span: undefined
      }
    ]
  },
  {
    name: 'category',
    label: 'Category',
    type: 'LIST',
    data: [
      {
        icon: 'attach_money',
        title: 'Financial Services',
        color: 'lightgray'
      },
      {
        icon: 'lightbulb',
        title: 'Human Capital',
        color: 'yellow'
      },
      {
        icon: 'person_pin',
        title: 'Customer Facing Services',
        color: 'red'
      },
      {
        icon: 'group',
        title: 'HR Related Services',
        color: 'teal'
      },
      {
        icon: 'corporate',
        title: 'Global Real Estate and Facilities',
        color: 'violet'
      },
      {
        icon: 'drafts',
        title: 'Marketing Communication',
        color: 'orange'
      }
    ]
  },
  {
    name: 'contract-type',
    label: 'Contract type',
    type: 'BOXES',
    data: [
      {
        icon: undefined,
        title: 'Governing',
        desc: 'Contracts like MRA, MSA, ISA, SLA, PPDA or MPA',
        span: undefined
      },

      {
        icon: undefined,
        title: 'Sub-Agreement',
        desc: 'Contracts like SOW, Schedule or Order Form',
        span: undefined
      },

      {
        icon: undefined,
        title: 'NDA',
        desc: 'Simple Non-disclosure agreement contract',
        span: undefined
      },
      {
        icon: undefined,
        title: 'Other',
        desc: 'Contracts that doesn’t fit into any previous category',
        span: undefined
      }
    ]
  }
]

export function FormTabsSection() {
  const [selected, setSelected] = useState(exampleData[0].name)

  const {info} = useToasts()
  const handleClick = (message: string) => {
    const id = exampleData.findIndex((i) => i.name === selected)
    if (id + 1 !== exampleData.length) {
      setSelected(exampleData[id + 1].name)
    }
    info(`You clicked on ${message}`)
  }

  return (
    <>
      <FormTabs selected={selected}>
        {exampleData.map((item, index) => {
          return (
            <Tab
              key={item.name}
              name={item.name}
              heading={`${index + 1}. ${item.label}`}
              className="w-full h-130 "
            >
              {tabContent(item.type, item.data, handleClick, item.label)}
            </Tab>
          )
        })}
      </FormTabs>
    </>
  )
}

const tabContent = (
  type: 'BOXES' | 'LIST',
  data: Data[],
  handleClick: (v: string) => void,
  label?: string
) => {
  switch (type) {
    case 'BOXES':
      const colSize = data.reduce((acc, d) => {
        const val = d.span ? d.span : 1

        return (acc = acc + val)
      }, 0)

      return (
        <RadioBoxContainer type="big" label={label} colSize={colSize}>
          {data.map((box: any, idx: number) => (
            <BigRadioBox
              key={`${box.title}${idx}`}
              icon={box.icon ? box.icon : undefined}
              title={box.title}
              description={box.desc}
              span={box.span ? box.span : 1}
              onClick={() => handleClick(box.title)}
            />
          ))}
        </RadioBoxContainer>
      )
    case 'LIST':
      return (
        <RadioBoxContainer
          type="big"
          className="mx-6"
          colSize={2}
          rowSize={data.length / 2}
          label="Category"
        >
          {data.map(({icon, title, color}, idx: number) => {
            return icon && color ? (
              <SmallRadioBox
                key={`${title}${idx}`}
                icon={icon}
                title={title}
                color={color}
                onClick={() => handleClick(title)}
              />
            ) : null
          })}
        </RadioBoxContainer>
      )
  }
}
