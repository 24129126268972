import React, {FC, useContext} from 'react'
import {AdvancedRadioValue, FieldTemplate, Project} from '@contractool/schema'
import {AppContext} from 'contexts'
import {IconCircle, getColors} from 'components/IconCircle'
export const ProjectLabels: FC<{project: Project}> = ({
  project
}) => {
  const {config} = useContext(AppContext)

  let fields = config.fields.filter((field: FieldTemplate) => {
    return field.showInProjectHeader === true
  })

  return (
    <>
      {fields.map((field: FieldTemplate) => {
        let selected = field.advancedRadioValues.filter((option: AdvancedRadioValue) => {
          return option.value === project.fields[field.name]
        })
        if (selected.length === 0) {
          return null
        }
        let colors = getColors(selected[0].color ? selected[0].color : '')

        return <div
          key={field.name}
          className={`${colors.bgColor} ${colors.textColor} self-center mr-4 pr-4 rounded-full flex items-center`}>
          {selected[0].icon && <div className={`mr-2 ${colors.bgColor} rounded-full`}>
            <IconCircle name={selected[0].icon} circleSize={10} iconSize={5} color={selected[0].color}/>
          </div>}
          {selected[0].title}
        </div>
      })}
    </>
  )
}
