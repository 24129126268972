import React, {FC} from 'react'

import {Icon, Icons} from './Icon'

export type TVariation = 'success' | 'warning' | 'error' | 'info';

export interface IToastOptions {
    onClose?: () => void
    variation: TVariation
    icon?: Icons
    message?: string
}

const settings: {
    [key in TVariation]: {
        name: Icons
        color: string
        background: string
    }
} = {
  success: {
    name: 'done',
    color: 'text-teal-700',
    background: 'bg-teal-100'
  },
  warning: {
    name: 'error',
    color: 'text-orange-600',
    background: 'bg-orange-100'
  },
  error: {
    name: 'close',
    color: 'text-red-700',
    background: 'bg-red-100'
  },
  info: {
    name: 'visibility',
    color: 'text-white',
    background: 'bg-blue-400'
  }
}

export const Toast: FC<IToastOptions> = ({onClose, variation, icon, message, children}) => {
  const setting = settings[variation]
  const border = variation === 'error' ? 'border-red-500 border' : ''

  return (
    <div className={`w-160 bg-white rounded-xl p-5 flex items-center justify-between shadow-lg select-none ${border}`}>
      <div className="flex items-center">
        <div className={`w-10 h-10 rounded-full flex items-center justify-center ${setting.background}`}>
          <Icon name={icon ?? setting.name} size={5} className={`${setting.color}`} />
        </div>
        <span className="mx-5 text-gray-700">
          {message ? message : children}
        </span>
      </div>

      {onClose && <button onClick={onClose} className="cursor-pointer focus:outline-none">
        <Icon name="close" size={6} className="text-gray-600" />
      </button>}
    </div>
  )
}
