import {FieldTemplate, Report} from '@contractool/schema'
import React, {FC, useState} from 'react'
import ReactTooltip from 'react-tooltip'

import {useRequest} from 'hooks/useRequest'
import {EventEmitter} from 'utils/eventEmitter'
import {ReportFilter} from '../ReportFilter'
import {Option} from 'components/Dropdown'
import ReportFilterHOC from '../ReportFilterHOC'

export const SupplierPoc: FC<{
    report: Report;
    selectedFilters: any;
    setSelectedFilters: any;
    handleFilterUpdate: (customFilter: any) => void;
    filterSettings: FieldTemplate[];
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({
  report,
  selectedFilters,
  setSelectedFilters,
  handleFilterUpdate,
  filterSettings,
  usersAsOptions,
  suppliersAsOptions
}) => {
  const [subscribed, setSubscribed] = useState(false)
  const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {
    params: selectedFilters
  })

  if (!data) {
    return null
  }

  if (!subscribed) {
    EventEmitter.subscribe('report-date-filter', (filter: any) => {
      setSelectedFilters(filter)
    })
    setSubscribed(true)
  }

  return (
    <div className="rounded-xl bg-white mb-3 ">
      <div className="flex justify-between border-b border-gray-100">
        <h1 className="p-5">{report.title}</h1>
        <ReportFilter
          selectedFilters={selectedFilters}
          filterSettings={filterSettings}
          reportSlug={report.slug}
          onFilter={handleFilterUpdate}
          usersAsOptions={usersAsOptions}
          suppliersAsOptions={suppliersAsOptions}
        />
      </div>
      <div className="p-5 overflow-auto">
        {Object.keys(data).map((userName, index) => {
          let userData = data[userName]
          let total = 0
          if (userData.length === 1) {
            total = userData[0].aging_days
          } else if (userData.length > 1) {
            let reduced = userData.reduce((project: any, project2: any) => {
              return (
                (typeof project === 'object' ? project.aging_days : project) +
                                project2.aging_days
              )
            })
            total = reduced / userData.length
          }
          
          return (
            <div key={`poc_${index}`} className="flex mb-3">
              <div style={{minWidth: '200px'}}>{userName}</div>
              <div
                style={{maxHeight: '18px', marginLeft: '20px'}}
                className="flex items-end"
              >
                {userData.map((project: any) => {
                  let style = {
                    width: '9px',
                    // borderRadius: '3px',
                    backgroundColor: project.color,
                    height: project.height + 'px',
                    marginLeft: '5px'
                  }

                  return (
                    <a
                      href={`/#/projects/${project.id}`}
                      style={style}
                      key={project.id}
                      data-tip
                      data-for={`project-${project.id}`}
                    >
                      <ReactTooltip
                        id={`project-${project.id}`}
                        backgroundColor="#F8F8F8"
                        borderColor={project.color}
                        border={true}
                        className={'shadow-xl rounded-xl p-3 text-gray-800 border'}
                        textColor="black"
                      >
                        {project.title}
                        <div className="mt-5 flex items-end">
                          <span
                            style={{
                              height: '12px',
                              width: '12px',
                              borderRadius: '6px',
                              backgroundColor: project.color
                            }}
                            className="mr-3 inline-block"
                          ></span>
                          <span style={{lineHeight: '1'}}>
                            {project.aging_days} days
                          </span>
                        </div>
                      </ReactTooltip>
                    </a>
                  )
                })}
                <div
                  className="leading-none ml-5 "
                  style={{fontWeight: 'bold', fontSize: '11px'}}
                >
                  {total.toFixed(1)}d
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const EnhancedSupplierPoc = ReportFilterHOC(SupplierPoc)

export default EnhancedSupplierPoc
