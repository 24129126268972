import React from 'react'
import {Button} from '../../components/Button'
import {Section, Variation} from './DesignSystem'
import {Menu, MenuItem} from '../../components/Menu'

export function Menus() {
  return (
    <>
      <Section title="Basic menu">
        <Variation hint="Popper menu positioned on the bottom">
          <Menu
            handle={({toggleMenu}) => (
              <Button
                color="white"
                size="small"
                radius="full"
                icon="more_horiz"
                onClick={toggleMenu}
              ></Button>
            )}
          >
            <MenuItem icon="people">Do something</MenuItem>
          </Menu>
        </Variation>
        <Variation hint="Auto-closing menu">
          <Menu
            autoClose={true}
            handle={({toggleMenu}) => (
              <Button
                color="white"
                size="small"
                radius="full"
                icon="more_horiz"
                onClick={toggleMenu}
              ></Button>
            )}
          >
            <MenuItem icon="people">Do something</MenuItem>
          </Menu>
        </Variation>
      </Section>
    </>
  )
}
