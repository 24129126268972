import {Project, Task} from '@contractool/schema'
import React, {FC} from 'react'
import ReactTooltip from 'react-tooltip'
import {Modal} from 'components/Modal'
import {Link, useParams, useHistory} from 'react-router-dom'
import {Button} from 'components/Button'
import {Field} from 'components/Field'
import {sign} from 'utils/auth'
import {translate} from 'utils/translations'
import {formatDeadline} from './TaskPanel'
import {Icon} from 'components/Icon'
import {Menu, MenuItem} from 'components/Menu'

export function TaskDetail({project, onClose}: {onClose: () => void; project: Project}) {
  const {taskId} = useParams<{taskId?: string}>()

  const task = project.tasks.find((t) => taskId && t.id === parseInt(taskId))
  if (!task) return null

  return (
    <Modal
      heading={
        <Link
          to={`/projects/${project.id}/tasks/${task.id}/resolve`}
          className="flex items-center"
        >
          {task.state === 'OPEN' ? (
            <>
              <div
                className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 rounded-full"
                title={translate('Complete task')}
              >
                <Icon name="done" size={5} />
              </div>
              <span
                className="text-base leading-tighter text-gray-700 ml-4"
                title={translate('Complete task')}
              >
                {translate('Complete task')}
              </span>
            </>
          ) : (
            <>
              <div
                className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                title={translate('Re-open task')}
              >
                <Icon name="done" size={5} />
              </div>
              <span
                className="text-base leading-tighter text-gray-700 ml-4"
                title={translate('Re-open task')}
              >
                {translate('Re-open task')}
              </span>
            </>
          )}
        </Link>
      }
      onClose={onClose}
      compact={true}
      corner={<StateMenu task={task} project={project} />}
    >
      <Link
        to={`/projects/${project.id}/tasks/${task.id}/edit`}
        className="text-xl text-gray-700"
      >
        {task.title}
      </Link>
      <div className="flex border-b border-gray-100 pb-12 mb-12 pt-12">
        <div className="w-1/3">
          <Field name="deadline" label={translate('Due date')}>
            <div className="mt-4 flex items-center">
              <Icon name="date_range" size={5} className="text-gray-600 inline" />
              <span className="ml-3">{formatDeadline(task.deadline)}</span>
            </div>
          </Field>
        </div>
        <div className="w-1/3">
          <Field name="assignee_id" label={translate('Assignee')}>
            <div className="mt-4">
              <img
                data-tip={task.assignee.name}
                src={sign(task.assignee.avatar_url)}
                alt={task.assignee.name}
                className="w-8 h-8 rounded-full inline "
              />
              <ReactTooltip />
              <span className="ml-3">{task.assignee.name}</span>
            </div>
          </Field>
        </div>
        <div className="w-1/3">
          <Field name="assignor_id" label={translate('Created by')}>
            <div className="mt-4">
              <img
                data-tip={task.assignor.name}
                src={sign(task.assignor.avatar_url)}
                alt={task.assignor.name}
                className="w-8 h-8 rounded-full inline "
              />
              <span className="ml-3">{task.assignor.name}</span>
            </div>
            <ReactTooltip />
          </Field>
        </div>
      </div>

      <div className="mb-12">
        <Field name="description" label={translate('Description')}>
          <textarea
            rows={6}
            className="pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight border-gray-200 focus:border-blue-700"
            disabled
            value={task.body}
          />
        </Field>
      </div>

      {task.comment && (
        <div>
          <label className="text-gray-600 select-none">{translate('Comment')}</label>
          <div className="mt-2 mb-2">{task.comment}</div>
        </div>
      )}

      <Modal.Footer className="flex justify-end">
        <Button color="white" onClick={onClose}>
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const StateMenu: FC<{task: Task; project: Project}> = ({task, project}) => {
  const history = useHistory()

  return (
    <Menu handle="more_vert">
      <MenuItem
        icon="edit"
        onClick={() => {
          history.push(`/projects/${project.id}/tasks/${task.id}/edit`)
        }}
      >
        {translate('Edit')}
      </MenuItem>
    </Menu>
  )
}
