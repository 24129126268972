import React from 'react'

const highlightWord = (item: string, input: string | null, highlightClass: string) => {
  if (input) {
    const lowercaseItem = item.toLowerCase()
    const lowercaseInput = input.toLowerCase()

    const index = lowercaseItem.indexOf(lowercaseInput)
    if (index >= 0) {
      const word = item.slice(index, index + lowercaseInput.length)
      const firstPart = item.slice(0, index)
      const lastPart = item.slice(index + lowercaseInput.length)

      return (
        <>
          {firstPart}
          <span className={highlightClass}>
            {word}
          </span>
          {lastPart}
        </>
      )
    }
  }

  return item
}

export default highlightWord
