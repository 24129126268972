interface SubscribeItem {
    eventName: string,
    subscribers: {(data: any): void}[]
}

export const EventEmitter = {
  events: new Array<SubscribeItem>(),
  dispatch: (event: string, data: any) => {
    console.log('dispatching', event, data)
    let items: SubscribeItem[]
    items = EventEmitter.events.filter((item: SubscribeItem) => {
      return item.eventName === event
    })
    if (items.length === 0) {
      // console.log('No listeners', event);
      return
    }
    items[0].subscribers.forEach((subscriber) => {
      // console.log('Dispatching listener', event);
      subscriber(data)
    })
  },
  unsubscribe: (event: string) => {
    EventEmitter.events = EventEmitter.events.map((item: SubscribeItem) => {
      if (item.eventName === event) {
        return {
          eventName: event,
          subscribers: []
        }
      }
      
      return item
    })
    // console.log('unsubscribe', EventEmitter.events);
  },
  subscribe: (event: string, callback: any) => {
    // console.log('EventEmmiter subscribe', event);
    let items: SubscribeItem[]
    items = EventEmitter.events.filter((item: SubscribeItem) => {
      return item.eventName === event
    })
    if (items.length === 0) {
      let subscriberItem: SubscribeItem = {
        eventName: event,
        subscribers: [callback]
      }
      EventEmitter.events.push(subscriberItem)
      
      return
    }
    items[0].subscribers.push(callback)
  }
}
