import React, {FC, ReactNode, useState} from 'react'
import {Icon} from 'components/Icon'

export const Field: FC<{
    name: string;
    label?: string;
    legend?: string;
    helptext?: string;
    className?: string;
    errorMessage?: string;
    required?: boolean;
    right?: ReactNode;
}> = ({name, children, label, legend, helptext, errorMessage, className, required = false, right}) => {
  const helpTextHtml = {
    dangerouslySetInnerHTML: {__html: helptext + ''}
  }
  const [helptTextVisible, setHelptTextVisible] = useState(false)

  return (
    <div className={className} data-id={`field-${name}`}>
      <div className="hover:bg-gray-150 min-h-20 p-2 pb-4 rounded">
        <div className="flex justify-between">
          <div>
            {label && (
              <label
                htmlFor={name}
                className={`block leading-normal select-none flex items-center text-md  ${
                  errorMessage ? 'text-red-700' : 'text-gray-600'
                }`}
              >
                {label}
                {required && <span className="ml-1 text-red-700">*</span>}
                {helptext && <span className="cursor-pointer ml-2" onClick={() => {
                  setHelptTextVisible(!helptTextVisible)
                }}><Icon name="question" size={5}/></span>}
              </label>
            )}
            {legend && <div className="text-gray-500 text-sm">{legend}</div>}
            {helptext && helptTextVisible && <div className="text-gray-500" {...helpTextHtml}/>}
          </div>
          {right && <div className="leading-normal select-none">{right}</div>}
        </div>

        <div>{children}</div>

        {errorMessage && (
          <p className="pt-2 text-sm text-red-700" role="alert">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  )
}
