import React from 'react'
import {Modal} from 'components/Modal'
import {Tab, Group} from '../../interfaces'
import {MemberModalForm, MemberModalTabs, ProjectFieldList} from './components'

const MemberModalContainerStyle = {
  height: '80vh'
}

const MemberModalStyle = {
  content : {
    width: '90%',
    maxWidth: '1500px'
  }
}

const MemberModal: React.FC<{memberKey: string, group: Tab | Group, isOpen: boolean, heading: string, closeModal: () => void}> = ({memberKey, group, isOpen, heading, closeModal}) => {
  const hasTabs = 'tabs' in group
  const tabGroup = group as Group
  const fieldGroup = group as Tab

  return (
    <Modal
      isOpen={isOpen}
      heading={heading}
      onClose={closeModal}
      containerStyle={MemberModalContainerStyle}
      style={MemberModalStyle}
    >
      <MemberModalForm
        memberKey={memberKey}
        group={group}
        closeModal={closeModal}
      >
        {hasTabs ? <MemberModalTabs tabs={tabGroup.tabs} /> : <ProjectFieldList fields={fieldGroup.fields} />}
      </MemberModalForm>
    </Modal>
  )
}

const PureMemberModal = React.memo(MemberModal)

export default PureMemberModal
