import React from 'react'
import {Section, Variation} from './DesignSystem'
import {Icon, IconNames} from 'components/Icon'
import _ from 'lodash'

export function Icons() {
  const iconsArrays = _.chunk(IconNames, 9)

  return (
    <>
      {iconsArrays.map((icons, index) => {
        return (
          <Section title={index === 0 ? 'Icons' : ''}>
            {icons.map((icon, i) => (
              <div className="w-42">
                <Variation hint={icon} center>
                  <Icon key={`${i}-${icon}`} name={icon} size={10} />
                </Variation>
              </div>
            ))}
          </Section>
        )
      })}
    </>
  )
}
