import * as React from 'react'

const MainPanel: React.FC<{heading: React.ReactNode; right?: React.ReactNode; className?: string}> = ({
  heading,
  right,
  className,
  children
}) => {
  return (
    <div className={className} data-cy-section={heading}>
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg">{heading}</div>
        {right}
      </div>

      {children}
    </div>
  )
}

export default MainPanel
