import moment from 'moment'
import React, {useContext} from 'react'

import {CurrencyInput} from 'components/form/inputs'
import {Switch} from 'components/Switch'
import {translate} from 'utils/translations'

import YearSelect from './YearSelect'
import MonthSelect from './MonthSelect'
import SavingInput from './SavingInput'
import {TMonth, IValue, IRecordWithSum} from './types'
import _ from 'lodash'
import {GuardContext} from 'contexts'
import {isUserInGroup} from 'utils/auth'

const monthNames = moment.monthsShort()
const months = monthNames.map((...args) => args[1] + 1)

interface IMonthlySavingsRange {
    startYear: number;
    startMonth: number;
    endYear: number;
    endMonth: number;
}

interface IMonthlySavingsProps {
    value: IValue | null;
    onChange: (v: IValue | null) => void;
}

const getYears = (startYear: number, endYear: number) => {
  if (endYear >= startYear) {
    return Array.from({length: endYear - startYear + 1}, (_, i) => i + startYear)
  }

  return []
}

const getYearLabel = (year: number) => {
  return `${year}y`
}

const parseYearLabel = (yearLabel: string) => {
  return Number(yearLabel.slice(0, -1))
}

const getMonthLabel = (month: number) => {
  return `${month}m`
}

const parseMonthLabel = (yearLabel: string) => {
  return Number(yearLabel.slice(0, -1))
}

const getValue = (range: IMonthlySavingsRange, originalValue: IValue | null) => {
  const final = originalValue?.final ?? 0
  const equalDistribution = originalValue?.equalDistribution ?? true

  const template = {
    final,
    equalDistribution
  } as IValue

  const {startYear, endYear} = range

  let getCellValue: (() => number) | ((year: number, month: number) => number)
  if (equalDistribution) {
    const {startMonth, endMonth} = range
    let count = (endYear - startYear) * 12 + endMonth - startMonth + 1

    const avg = Math.round(final / count)
    const lastMonthValue = final - (count - 1) * avg
    getCellValue = () => {
      count--
      if (count === 0) {
        return lastMonthValue
      } else {
        return avg
      }
    }
  } else {
    getCellValue = (year: number, month: number) => {
      if (originalValue?.years) {
        const originalCellValue = ((originalValue.years[getYearLabel(year)]) as IRecordWithSum)?.months[getMonthLabel(month) as TMonth]

        return originalCellValue ? originalCellValue : 0
      }

      return 0
    }
  }

  const years = {} as {
        [key: string]: IRecordWithSum
    }
  let sum = 0
  getYears(startYear, endYear).forEach(year => {
    years[getYearLabel(year)] = {
      months: {},
      sum: 0
    }
    months.forEach((month: number) => {
      if (
        !(year === startYear && month < range.startMonth) &&
                !(year === endYear && month > range.endMonth)
      ) {
        const cellValue = getCellValue(year, month)
        years[getYearLabel(year)].months[getMonthLabel(month) as TMonth] = cellValue
        years[getYearLabel(year)].sum += cellValue
      }
    })
    sum += years[getYearLabel(year)].sum
  })
  template.years = years
  template.sum = sum

  return template
}

const refreshYearSummary: (value: IValue, year: number) => void = (value, year) => {
  if (!value.years) { return }

  let sum = 0
  const months = Object.keys(value.years[getYearLabel(year)].months).map(parseMonthLabel)
  months.forEach(month => {
    sum += value.years
      ? value.years[getYearLabel(year)].months[getMonthLabel(month) as TMonth] ?? 0
      : 0
  })

  value.years[getYearLabel(year)].sum = sum
}

const refreshSum: (value: IValue) => void = (value) => {
  if (!value.years) { return }

  let sum = 0
  const years = Object.keys(value.years).map(parseYearLabel)
  years.forEach(year => {
    sum += value.years
      ? value.years[getYearLabel(year)].sum
      : 0
  })

  value.sum = sum
}

const parseRange: (value: IValue | null) => (IMonthlySavingsRange | null) = value => {
  if (null === value) { return null }
  if (!value.years) { return null }

  const years = Object.keys(value.years).map(parseYearLabel)
  const startYear = Math.min(...years)
  const endYear = Math.max(...years)

  const startYearMonthRecords = value.years[getYearLabel(startYear)].months
  const startYearMonths = Object.keys(startYearMonthRecords).map(parseMonthLabel)
  const startMonth = Math.min(...startYearMonths)

  const endYearMonthRecords = value.years[getYearLabel(endYear)].months
  const endYearMonths = Object.keys(endYearMonthRecords).map(parseMonthLabel)
  const endMonth = Math.max(...endYearMonths)

  return {startYear, startMonth, endYear, endMonth}
}

const defaultRange = {
  startYear: null,
  startMonth: null,
  endYear: null,
  endMonth: null
}

const MonthlySavings: React.FC<IMonthlySavingsProps> = ({value, onChange}) => {
  // computed cache parsedRange strongly depends on prop - value
  // 'value || null' is because default value of form field is ''
  // todo: 'value || null' can be updated to 'value' after form field default value is updated from '' to null
  const parsedRange = React.useMemo(() => parseRange(value || null), [value])

  // state
  const [range, setRange] = React.useState<{
        startYear: null | number
        startMonth: null | number
        endYear: null | number
        endMonth: null | number
    }>(parsedRange ?? defaultRange)
    // range strongly depends on parsedRange - cache
  React.useEffect(() => {
    setRange(parsedRange ?? defaultRange)
  }, [parsedRange])

  const handleStartYearChange = React.useCallback((startYear: number | null) => {
    setRange(previousRange => previousRange.startYear !== startYear
      ? {...previousRange, startYear}
      : previousRange
    )
  }, [])

  const handleStartMonthChange = React.useCallback((startMonth: number | null) => {
    setRange(previousRange => previousRange.startMonth !== startMonth
      ? {...previousRange, startMonth}
      : previousRange
    )
  }, [])

  const handleEndYearChange = React.useCallback((endYear: number | null) => {
    setRange(previousRange => previousRange.endYear !== endYear
      ? {...previousRange, endYear}
      : previousRange
    )
  }, [])

  const handleEndMonthChange = React.useCallback((endMonth: number | null) => {
    setRange(previousRange => previousRange.endMonth !== endMonth
      ? {...previousRange, endMonth}
      : previousRange
    )
  }, [])

  // state
  const [valueState, setValueState] = React.useState<IValue | null>(value)
  // valueState strongly depends on prop - value
  React.useEffect(() => {
    setValueState(value ? JSON.parse(JSON.stringify(value)) : value)
  }, [value])

  // methods
  const handleInputChange = React.useCallback((value: string) => {
    setValueState(previousValueState => ({
      ...previousValueState,
      final: Number(value)
    }))
  }, [])

  const toggleEqualDistribution = React.useCallback((value: boolean) => {
    setValueState(previousValueState => ({
      ...previousValueState,
      equalDistribution: value
    }))
  }, [])

  const handleCellChange = (year: number, month: number, input: string) => {
    if (valueState === null) { return }
    if (!valueState.years) { return }

    const numericValue = Number(input)
    valueState.years[getYearLabel(year)].months[getMonthLabel(month) as TMonth] = isNaN(numericValue) ? 0 : numericValue
    refreshYearSummary(valueState, year)
    refreshSum(valueState)
    setValueState({...valueState})
  }

  // calculations
  const {
    startYear,
    startMonth,
    endYear,
    endMonth
  } = range

  const isRangeReady = startYear && startMonth && endYear && endMonth &&
        (startYear < endYear || (startYear === endYear && startMonth <= endMonth))

  // effects
  React.useEffect(() => {
    if (isRangeReady) {
      const range = parseRange(value)
      if (
        range?.startYear !== startYear ||
        range?.startMonth !== startMonth ||
        range?.endYear !== endYear ||
        range?.endMonth !== endMonth ||
        value?.equalDistribution !== valueState?.equalDistribution ||
        value?.final !== valueState?.final ||
        value?.sum !== valueState?.sum
      ) {
        const updatedValue = getValue({
          startYear,
          startMonth,
          endYear,
          endMonth
        } as IMonthlySavingsRange, valueState)
        onChange(updatedValue)
      }
    }
  }, [isRangeReady, startYear, startMonth, endYear, endMonth, onChange, value, valueState])

  const maxYear = React.useMemo(() => 4, [])

  const {user} = useContext(GuardContext)
  const hasSuperPower = ((isUserInGroup(user, 'admin') || isUserInGroup(user, 'finance_approver')))

  return (
    <div className="monthly-savings">
      <div className="mb-6 w-64">
        <CurrencyInput
          name="values"
          value={String(valueState?.final ?? '')}
          locale="en-US"
          currency="USD"
          onChange={handleInputChange}
          className="py-5 focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 border-gray-200 focus:border-blue-700"
        />
      </div>
      <Switch
        value={valueState?.equalDistribution ?? true}
        onChange={toggleEqualDistribution}
      >
        Equal distribution
      </Switch>
      <div className="monthly-savings__range" >
        <div className="monthly-savings__range-start" >
          <div className="flex w-80 items-center justify-between" data-testid="start">
            <span>
              {translate('Start year: ')}
            </span>
            <YearSelect
              value={startYear}
              onChange={handleStartYearChange}
              placeholder="Year Start"
              max={maxYear}
            />
          </div>
          <div className="flex w-80 items-center justify-between" data-testid="start month">
            <span>
              {translate('Start month: ')}
            </span>
            <MonthSelect
              value={startMonth}
              onChange={handleStartMonthChange}
              placeholder="Month Start"
              from={hasSuperPower ? 1 : (startYear === 2021 ? 11 : 1)}
              to={12}
            />
          </div>
        </div>

        {startYear && startMonth && (
          <div className="monthly-savings__range-end" >
            <div className="flex w-80 items-center justify-between" data-testid="end">
              <span>
                {translate('End year: ')}
              </span>
              <YearSelect
                value={endYear}
                onChange={handleEndYearChange}
                placeholder="Year End"
                years={hasSuperPower ? _.range(startYear, startYear + 10) : _.range(startYear, startYear + 4)}
              />
            </div>
            <div className="flex w-80 items-center justify-between" data-testid="end month">
              <span>
                {translate('End month: ')}
              </span>
              <MonthSelect
                value={endMonth}
                onChange={handleEndMonthChange}
                placeholder="Month End"
                from={startYear === endYear ? startMonth : 1}
                to={hasSuperPower ? 12 : (startYear + 3 === endYear ? startMonth : 12)}
              />
            </div>
          </div>
        )}
      </div>

      {isRangeReady && valueState?.years && (
        <table className="monthly-savings__values" data-testid="saving table">
          <thead>
            <tr className="monthly-savings__values-header">
              <th className="monthly-savings__values-year" />
              {monthNames.map((monthName: string) => (
                <th key={monthName} className="monthly-savings__values-month">
                  {monthName}
                </th>
              ))}
              <th className="monthly-savings__values-sum">
                {translate('Sum')}
              </th>
            </tr>
          </thead>
          <tbody className="monthly-savings__values-body">
            {Object.keys(valueState.years).map(parseYearLabel).map(year => (
              <tr className="monthly-savings__values-row" key={year}>
                <td className="monthly-savings__values-year">
                  {year}
                </td>
                {months.map((month: number) => (
                  <td key={month} className="monthly-savings__values-month">
                    {
                      valueState.years && (
                        <SavingInput
                          disabled={valueState.equalDistribution}
                          value={valueState.years[getYearLabel(year)].months[getMonthLabel(month) as TMonth]}
                          onChange={(value: string) => handleCellChange(year, month, value)}
                        />
                      )
                    }
                  </td>
                ))}
                <td className="monthly-savings__values-sum">
                  {valueState.years && (
                    <SavingInput
                      bold
                      disabled
                      value={valueState.years[getYearLabel(year)].sum}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="monthly-savings__values-footer">
            <tr className="monthly-savings__values-row">
              <td className="text-right" colSpan={13}>
                {translate('Sum all savings: ')}
              </td>
              <td className="monthly-savings__values-sum">
                <SavingInput
                  bold
                  disabled
                  invalid={valueState.sum !== valueState.final}
                  value={valueState.sum}
                />
              </td>
            </tr>
            <tr className="monthly-savings__values-row">
              <td className="text-right" colSpan={13}>
                {translate('Final savings: ')}
              </td>
              <td className="monthly-savings__values-sum">
                <SavingInput
                  bold
                  disabled
                  invalid={valueState.sum !== valueState.final}
                  value={valueState.final}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  )
}

export default MonthlySavings
