import React, {ReactElement} from 'react'

import {Icon, Icons, Size} from './Icon'

export type Color =
    | 'orange'
    | 'green'
    | 'red'
    | 'blue'
    | 'violet'
    | 'cyan'
    | 'pink'
    | 'yellow'
    | 'teal'
    | 'lightgray';

export function getColors(color: string) {
  let textColor = ''
  let bgColor = ''
  switch (color) {
    case 'orange':
      textColor = 'text-orange-700'
      bgColor = 'bg-orange-000'
      break
    case 'green':
      textColor = 'text-green-700'
      bgColor = 'bg-green-000'
      break
    case 'red':
      textColor = 'text-red-700'
      bgColor = 'bg-red-000'
      break
    case 'blue':
      textColor = 'text-blue-700'
      bgColor = 'bg-blue-000'
      break
    case 'violet':
      textColor = 'text-violet-700'
      bgColor = 'bg-violet-000'
      break
    case 'cyan':
      textColor = 'text-cyan-700'
      bgColor = 'bg-cyan-000'
      break
    case 'pink':
      textColor = 'text-pink-700'
      bgColor = 'bg-pink-000'
      break
    case 'yellow':
      textColor = 'text-yellow-700'
      bgColor = 'bg-yellow-000'
      break
    case 'teal':
      textColor = 'text-teal-700'
      bgColor = 'bg-teal-000'
      break
    case 'lightgray':
      textColor = 'text-gray-500'
      bgColor = 'bg-gray-000'
      break
    default:
      textColor = 'text-gray-700'
      bgColor = 'bg-gray-000'
      break
  }

  return {
    textColor,
    bgColor
  }
}

export function IconCircle({
  name,
  color,
  className = '',
  circleSize,
  iconSize
}: {
    name: Icons;
    color?: Color;
    className?: string;
    circleSize: Size;
    iconSize: Size;
}): ReactElement {
  className = className + ' flex justify-center rounded-full ' + sizeStyles(circleSize)

  let colors = getColors(color ? color : '')

  return <div className={`${className} ${colors.textColor} ${colors.bgColor}`}>
    <Icon name={name} className="self-center" size={iconSize}/>
  </div>
}

function sizeStyles(s: Size): string {
  return `w-${s} h-${s}`
}
