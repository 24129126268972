import React, {FC} from 'react'
import {Modal} from 'components/Modal'
import {Button} from 'components/Button'

export const AppVersionModal: FC<{ beVersion:string }> = ({beVersion}) => {
  const reload = () => {
    localStorage.setItem('_app_version', beVersion)
    window.location.reload(true)
  }

  return <Modal onClose={reload} heading="New version available!">
    <div className="card-body text-gray-800">
      <p className="mb-4 font-medium">A new version of Cequence is now available.</p>

      <p>We update the app regularly to make it faster and more reliable for you.
         Simply click on the <span className="italic">Reload application</span> button
         and the new version will be launched.
      </p>
    </div>

    <div className="card-footer text-center mt-8">
      <Button onClick={reload} color="blue">Reload application</Button>
    </div>
  </Modal>
}
