import {Report} from '@contractool/schema'
import React, {FC, useState} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import {useRequest} from 'hooks/useRequest'
import {EventEmitter} from 'utils/eventEmitter'

const AverageStateTat: FC<{report: Report}> = ({report}) => {
  const [selectedFilters, setSelectedFilters] = useState(report.user_filters)

  const [subscribed, setSubscribed] = useState(false)
  const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {
    params: selectedFilters
  })
  if (!data) {
    return null
  }

  if (!subscribed) {
    EventEmitter.subscribe('report-date-filter', (dateFilter: any) => {
      setSelectedFilters({...selectedFilters, ...dateFilter})
    })
    EventEmitter.subscribe('open-by-state-filter', (customFilter: any) => {
      setSelectedFilters({...selectedFilters, ...customFilter})
    })

    setSubscribed(true)
  }
  data.plotOptions.series = {
    events: {
      click: function (event: any) {
        let state
        let quartal
        let states
        // @ts-ignore
        switch (this.name) {
          case 'JI Proposal':
            state = 'proposal'
            break
          case 'Internal Alignment':
            state = 'alignment'
            break
          case 'Negotiation':
            state = 'negotiation'
            break
          case 'Field Team':
            state = 'field'
            break
          case 'Director Review':
            state = 'director'
            break
          case 'Internal Review':
            state = 'internal'
            break
          case 'Vendor Signed':
            state = 'vendor'
            break
          case 'Executed':
            state = 'executed'
            break
          default:
            //Todo ji rozmotat dva reporty
            quartal = event.point.category
            states = getStateGroup(event.point.series.name)
        }
        EventEmitter.dispatch('report-projects', {
          report: {
            slug: report.slug
          },
          ...selectedFilters,
          state,
          states,
          quartal
        })
      }
    }
  }
  
  return (
    <div className="rounded-xl bg-white mb-3">
      <div className="text-sm text-gray-500 ml-5 flex justify-between">{report.title}</div>
      <div className="p-5 ">
        <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={data} />
      </div>
    </div>
  )
}

function getStateGroup(stateGroup: string) {
  switch (stateGroup) {
    case 'Vendor Signed & Executed':
      return ['vendor', 'executed']

    case 'JI Proposal':
      return ['proposal']
    case 'Internal Alignment & Negotiation':
      return ['negotiation', 'alignment']
  }
  
  return ['field', 'director', 'internal']
}

export default AverageStateTat
