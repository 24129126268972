import React, {FC, ReactNode, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import ClipLoader from 'react-spinners/ClipLoader'

const START_SMALL_LOADER_EVENT = 'StartSmallLoader'
const STOP_SMALL_LOADER_EVENT = 'StopSmallLoader'
const START_BIG_LOADER_EVENT = 'StartBigLoader'
const STOP_BIG_LOADER_EVENT = 'StopBigLoader'

export function useSmallLoader() {
  return {
    start: (loadingText: ReactNode) =>
      dispatchLoaderEvent(START_SMALL_LOADER_EVENT, loadingText),
    stop: () => dispatchLoaderEvent(STOP_SMALL_LOADER_EVENT)
  }
}

export function useBigLoader() {
  return {
    start: (loadingText: ReactNode) => dispatchLoaderEvent(START_BIG_LOADER_EVENT, loadingText),
    stop: () => dispatchLoaderEvent(STOP_BIG_LOADER_EVENT)
  }
}

function dispatchLoaderEvent(
  type: 'StartBigLoader' | 'StopBigLoader' | 'StartSmallLoader' | 'StopSmallLoader',
  loadingText?: ReactNode
) {
  let event
  const detail = Object.assign({loadingText: loadingText})
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(type, {detail})
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(type, false, false, detail)
  }

  window.dispatchEvent(event)
}

export const SmallLoader: FC<{
    textColor?: string;
    bgColor?: string;
}> = ({textColor = '#ffffff', bgColor = '#2979ff'}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<string>('')

  const startLoading = (customEvent: any) => {
    setLoading(true)
    setLoadingText(customEvent.detail.loadingText)
  }

  const stopLoading = () => {
    setLoading(false)
    setLoadingText('')
  }

  useEffect(() => {
    window.addEventListener(START_SMALL_LOADER_EVENT, startLoading)
    window.addEventListener(STOP_SMALL_LOADER_EVENT, stopLoading)

    return () => {
      window.removeEventListener(START_SMALL_LOADER_EVENT, startLoading)
      window.removeEventListener(STOP_SMALL_LOADER_EVENT, stopLoading)
    }
  })

  return loading
    ? createPortal(
      <div
        className={'absolute flex items-center justify-center px-4 py-2 rounded-lg'}
        style={{top: '62px', right: '15px', backgroundColor: bgColor}}
      >
        <ClipLoader loading={loading} size={15} color={textColor} />
        {loadingText.length ? (
          <span className="ml-3" style={{color: textColor}}>
            {loadingText}
          </span>
        ) : null}
      </div>,
      document.body
    )
    : null
}

export const BigLoader: FC<{textColor?: string}> = ({textColor = '#ffffff'}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<string>('')

  const startLoading = (customEvent: any) => {
    setLoading(true)
    setLoadingText(customEvent.detail.loadingText)
  }

  const stopLoading = () => {
    setLoading(false)
    setLoadingText('')
  }

  useEffect(() => {
    window.addEventListener(START_BIG_LOADER_EVENT, startLoading)
    window.addEventListener(STOP_BIG_LOADER_EVENT, stopLoading)

    return () => {
      window.removeEventListener(START_BIG_LOADER_EVENT, startLoading)
      window.removeEventListener(STOP_BIG_LOADER_EVENT, stopLoading)
    }
  })

  return loading
    ? createPortal(
      <div
        className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
        style={{backgroundColor: '#00000070'}}
      >
        <ClipLoader loading={loading} size={75} color={textColor} />
        {loadingText ? (
          <span className="ml-6 text-lg" style={{color: textColor}}>
            {loadingText}
          </span>
        ) : null}
      </div>,
      document.body
    )
    : null
}
