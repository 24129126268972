import React, {FC} from 'react'
import {useDropzone} from 'react-dropzone'
import {useUploadingReducer} from '../../components/Avatar'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import {upload} from '../../utils/upload'
import {Response} from '../../utils/http'
import {Icon} from '../../components/Icon'
import {Document} from '@contractool/schema'
import {translate} from 'utils/translations'

export const DocumentUpload: FC<{
    onSend: (file: {path: string; name: string}) => Promise<Response<any>>;
    singleFile?: boolean;
    onUpload?: (document: Document) => void;
    className?: string;
}> = ({onSend, onUpload, className, singleFile = false}) => {
  const [{uploading, progress}, dispatch] = useUploadingReducer()

  const startUpload = (file: File) => {
    console.log('START UPLOAD')
    dispatch({type: 'start_uploading'})

    upload(file, {
      onUploadProgress: (progress) => {
        dispatch({type: 'progress_uploading', progress})
      }
    })
      .then((file) => onSend(file))
      .then((response) => {
        dispatch({type: 'finish_uploading'})

        onUpload && onUpload(response.data)
      })
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (files) => {
      if (singleFile === true) return startUpload(files[0])
      files.forEach((file) => startUpload(file))
    }
  })

  return (
    <>
      {uploading && (
        <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
          <CircularProgressbar
            value={progress}
            className="w-6 h-6"
            strokeWidth={5}
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: '#2979ff',
              trailColor: '#e9ecef'
            })}
          />
        </div>
      )}
      <div
        className={`focus:outline-none ${className}`}
        {...getRootProps()}
        onClick={() => {}}
      >
        <input type="file" className="hidden" {...getInputProps()} />

        <div className="bg-gray-000 text-gray-600 p-7 rounded-xl flex items-center justify-center">
          <Icon name="cloud_upload" size={6} />
          <div className="pl-3">
            {translate('Drop file here or')}{' '}
            <span
              className="text-blue-700 cursor-pointer focus:outline-none"
              {...getRootProps({props: 'onClick'})}
            >
              {translate('browse it')}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
