import React, {FC} from 'react'
import {ActivityContainer, ActivityHeader} from '../ActivityPanel'
import {LogFull} from '@contractool/schema'

export const BasicLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {
  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={desc}
        created_at={log.created_at}
        timezone={log.causer.timezone}
      />
    </ActivityContainer>
  )
}
