import {ReportTab} from '@contractool/schema'
import {createContext} from 'react'

export const Context = createContext<{
    tab: ReportTab,
    idx: number,

}>({
  tab: null as unknown as ReportTab,
  idx: null as unknown as number
})
