import React, {FC, ReactNode} from 'react'
import {FieldTemplate, Project, ProjectStatistics} from '../../../../../_common/schema'
import {SidePanel} from '../ProjectDetail'
import {Icon} from '../../../components/Icon'
import moment from 'moment'
import {useBigLoader} from 'components/Loader'
import {translate} from 'utils/translations'
import {Avatar} from 'components/Avatar'
import {ProjectStateIcon} from 'views/projects/ProjectStateIcon'
import {DummyList} from 'components/DummyList'
import {http} from 'utils/http'
import ReactTooltip from 'react-tooltip'

export const RelatedProjects: FC<{ project: Project }> = ({project}) => {
  let parent = project
  if (project.parent) {
    parent = project.parent
  }

  const bigLoader = useBigLoader()
  const navigate = (id: any) => {
    bigLoader.start(translate('Opening project...'))
    window.location.href = `/#/projects/${id}`
    window.location.reload()
  }

  return (
    <SidePanel heading="Related Projects">
      {parent.id !== project.id && (
        <fieldset
          className={`p-8 border hover:bg-gray-100 rounded  pt-0 ${
            parent.id === project.id ? 'border-blue-300' : 'border-gray-300'
          }`}
        >
          <legend className="text-gray-600 text-center p-4">
            {translate('Parent Project')}
          </legend>
          <div>
            <div
              onClick={() => {
                navigate(parent.id)
              }}
              className="flex items-center cursor-pointer hover:underline">
              <Icon name={'file-alt'} size={5} className="text-gray-600 mr-4 mb-0"/>
              <div>
                {parent.title}
                <small> ({moment(parent.created_at).format('DD-MM-YYYY')})</small>
              </div>
            </div>
          </div>
        </fieldset>
      )}
      <fieldset className={'pt-4 pb-8 border-gray-300'}>
        <table className="table">
          <tbody>
            {parent.children.map((child: Project) => {
              return (
                <tr
                  onClick={() => {
                    navigate(child.id)
                  }}
                  key={`child${child.id}`}
                  className={`rounded align-middle table-row border-b-1 border-gray-100 py-2 ${
                    child.id === project.id ? ' text-blue-700' : ' hover:bg-gray-000 cursor-pointer'
                  }`}
                >
                  <ProjectLink project={child}>
                    {child.fields['project_type']}
                  </ProjectLink>
                  <ProjectLink project={child}>
                    {child.fields['template']}
                  </ProjectLink>
                  <ProjectLink project={child}>
                    {child.team.assignee ? (
                      <div data-tip={child.team.assignee.name} className="flex">
                        <Avatar
                          user={child.team.assignee}
                          className="w-10 h-10 mr-4"
                        />
                        <div
                          className="self-center"
                          title={child.team.assignee?.name}
                        >
                        </div>
                      </div>
                    ) : (
                      <div className="text-gray-600 ml-2">-</div>
                    )}
                  </ProjectLink>
                  {/*<ProjectLink project={project}>*/}
                  {/*  <div className="flex">*/}
                  {/*    <ProjectStateIcon state={project.state}/>*/}
                  {/*    <div className={'ml-4 self-center'}>*/}
                  {/*      {project.state.label}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</ProjectLink>*/}
                  <ProjectLink project={child}>
                    <ProjectStatistic statistics={child.statistics} />
                  </ProjectLink>
                  <ProjectLink project={child}>{child.extra['aging_human']}</ProjectLink>
                </tr>
              )})}
          </tbody>
        </table>
      </fieldset>
    </SidePanel>
  )
}

const ProjectStatistic = ({statistics}: { statistics: Array<ProjectStatistics> }) => {
  return (
    <div className="flex">
      {statistics.map((statistics, index) => (
        <div
          key={index}
          title={statistics.title}
          className={`mr-1 w-4 h-2 ${
            statistics.type !== 'next' ? 'bg-blue-500' : 'bg-gray-200'
          } rounded-full`}
        ></div>
      ))}
    </div>
  )
}

const ProjectLink = ({children, project} : { children?: ReactNode, project: Project }) => {
  return <td className="cursor-pointer" style={{padding: 0}}>
    <a href={`/#/projects/${project.id}`} rel="noopener noreferrer" className="px-6 py-6 block w-full">
      {children}
    </a>
  </td>
}
