import {FieldTemplate, Report} from '@contractool/schema'
import React, {FC, useState} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {useRequest} from 'hooks/useRequest'
import {EventEmitter} from 'utils/eventEmitter'
import {ReportFilter} from '../ReportFilter'
import {Option} from 'components/Dropdown'
import ReportFilterHOC from '../ReportFilterHOC'

const Funding: FC<{
    report: Report;
    selectedFilters: any,
    setSelectedFilters: any,
    handleFilterUpdate: (customFilter: any) => void,
    filterSettings: FieldTemplate[],
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({report, selectedFilters, setSelectedFilters, handleFilterUpdate, filterSettings, usersAsOptions, suppliersAsOptions}) => {
  const [subscribed, setSubscribed] = useState(false)
  const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {params: selectedFilters})
  if (!data) {
    return null
  }

  if (!subscribed) {
    EventEmitter.subscribe('report-date-filter', (filter: any) => {
      setSelectedFilters(filter)
    })

    setSubscribed(true)
  }
  data.plotOptions.series = {
    events: {
      click: function (event: any) {
        let productGroup = event.point.name.replace(/ \d+%/, '')

        EventEmitter.dispatch('report-projects', {
          ...selectedFilters,
          'fields[product_group]': productGroup,
          report
        })
      }
    }
  }
  
  return (
    <div>
      <div className="flex justify-between">
        <h1 className="p-5 border-b border-gray-100">{report.title}</h1>
        <ReportFilter
          selectedFilters={selectedFilters}
          filterSettings={filterSettings}
          reportSlug={report.slug}
          onFilter={handleFilterUpdate}
          usersAsOptions={usersAsOptions}
          suppliersAsOptions={suppliersAsOptions}
        />
      </div>
      <div className="p-5 ">
        <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={data} />
      </div>
    </div>
  )
}

const EnhancedFunding = ReportFilterHOC(Funding)

export default EnhancedFunding
