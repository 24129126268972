import * as React from 'react'

import {Icon, Icons} from 'components/Icon'
import {cx} from 'utils/classnames'

interface chartTypeOption {
    icon: Icons,
    label: string,
    value: string,
}

const chartTypeOptions: chartTypeOption[] = [// should support echart, todo: might fetch from back-end
  {label: 'Pie Chart', value: 'pie-chart', icon: 'chart-pie'},
  {label: 'Vertical Stack Chart', value: 'vertical-stack-bar', icon: 'chart-vertical'},
  {label: 'Horizontal Stack Chart', value: 'horizontal-stack-bar', icon: 'chart-horizontal'},
  {label: 'Linear Chart', value: 'line-chart', icon: 'chart-linear'},
  {label: 'Simple Box', value: 'simple-box', icon: 'looks_4'},
  {label: 'Number Cards', value: 'number-cards', icon: 'filter_8'}
]

const ChartTypeSelect: React.FC<{
    injectedClassName: string,
    name: string,
    value: string,
    hasError: boolean,
    onChange: (v: string) => void
}> = ({injectedClassName, name, onChange, value: fieldValue}) => {
  return (
    <ul className={cx(injectedClassName, 'flex justify-around')}>
      {chartTypeOptions.map((option: chartTypeOption) => (
        <ChartTypeListItem
          key={option.value}
          name={name}
          onChange={onChange}
          option={option}
          fieldValue={fieldValue}
        />
      ))}
    </ul>
  )
}

export default ChartTypeSelect

const ChartTypeListItem: React.FC<{
    name: string,
    onChange: (v: string) => void,
    option: chartTypeOption,
    fieldValue: string,
}> = ({name, onChange, option, fieldValue}) => {
  const {icon, label, value} = option

  const id = `chart-type-${value}`
  const checked = fieldValue === value
  const handleChange = (e: any) => {
    onChange(e.target.value)
  }

  return (
    <li title={label}>
      <input
        checked={checked}
        className="hidden"
        id={id}
        name={name}
        onChange={handleChange}
        type="radio"
        value={value}
      />
      <label
        className={cx(
          'block',
          'border-2',
          'cursor-pointer',
          checked ? 'border-blue-800' : 'border-transparent-000',
          'hover:border-blue-700',
          'p-3',
          'rounded'
        )}
        htmlFor={id}
      >
        <Icon name={icon} size={12}/>
      </label>
    </li>
  )
}
