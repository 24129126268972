import {FieldTemplate, Report} from '@contractool/schema'
import React, {FC, useState} from 'react'

import {useRequest} from 'hooks/useRequest'
import {EventEmitter} from 'utils/eventEmitter'
import {Icon} from 'components/Icon'
import {ReportFilter} from '../ReportFilter'
import {Option} from 'components/Dropdown'
import ReportFilterHOC from '../ReportFilterHOC'

const Trend: FC<{
    report: Report;
    projectFilter?: any;
    selectedFilters: any,
    setSelectedFilters: any,
    handleFilterUpdate: (customFilter: any) => void,
    filterSettings: FieldTemplate[],
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({report, projectFilter = {}, selectedFilters, setSelectedFilters, handleFilterUpdate, filterSettings, usersAsOptions, suppliersAsOptions}) => {
  const [subscribed, setSubscribed] = useState(false)
  const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {params: selectedFilters})

  if (!data) {
    return null
  }

  if (!subscribed) {
    EventEmitter.subscribe('report-date-filter', (filter: any) => {
      setSelectedFilters(filter)
    })

    setSubscribed(true)
  }

  let newProjectFilter = {...projectFilter}
  newProjectFilter.report = {slug: report.slug}

  return (
    <div className="rounded-xl bg-white mb-3">
      <div className="pl-5 pt-0 pb-10">
        <div className="flex flex-row-reverse">
          <ReportFilter
            selectedFilters={selectedFilters}
            filterSettings={filterSettings}
            reportSlug={report.slug}
            onFilter={handleFilterUpdate}
            usersAsOptions={usersAsOptions}
            suppliersAsOptions={suppliersAsOptions}
          />
        </div>
        <span
          className="text-3xl mr-5 text-gray-800 cursor-pointer hover:text-blue-500"
          onClick={() => {
            EventEmitter.dispatch('report-projects', {...newProjectFilter, ...selectedFilters})
          }}
        >
          {data[0]}
        </span>
        {data[1] !== false && (
          <span className={`text-sm ${data[1] > 0 ? 'text-green-600' : 'text-red-600'}`}>
            {data[1] > 0 ? '+' : ''} {data[1]}
            <Icon
              name="triangle"
              size={5}
              className={`inline ml-2 ${data[1] < 0 ? 'rotate-180' : ''}`}
            />
          </span>
        )}
        <div className="mt-3">{report.title}</div>
      </div>
    </div>
  )
}

const EnhancedTrend = ReportFilterHOC(Trend)

export default EnhancedTrend
