import React, {useState} from 'react'
import {Section, Variation} from './DesignSystem'
import {DatePicker} from '../../components/DatePicker'
import {format} from 'date-fns'

export function DatePickers() {
  const [date, setDate] = useState<string | null>('2021-01-28')
  const [date2, setDate2] = useState<string | null>(null)

  return (
    <>
      <Section title="Date picker">
        <Variation hint="Basic date picker">
          <DatePicker name="date_1" value={date} onChange={setDate} />
        </Variation>

        <Variation hint="Empty state">
          <DatePicker
            name="date_2"
            value={date2}
            onChange={setDate2}
            placeholder="At some point..."
          />
        </Variation>
      </Section>

      <Section title="Formatting">
        <Variation hint="You can customize input text formatting">
          <DatePicker
            name="date_3"
            value={date}
            onChange={setDate}
            formatInput={(date) => format(new Date(date), 'EEEE, do MMM yyyy')}
          />
        </Variation>
      </Section>
    </>
  )
}
