import React, {FC} from 'react'
import {Project, DocumentSignature} from '../../../../../_common/schema'
import {SidePanel} from '../ProjectDetail'
import {Icon} from 'components/Icon'

export const WetSign: FC<{ project: Project }> = ({project}) => {

  const color = (signature: DocumentSignature) => {
    switch (signature.status) {
      case 'Pending' :
        return 'text-orange-500'
      case 'Signed' :
        return 'text-green-500'
      case 'Rejected' :
        return 'text-red-500'
    }
    
    return ''
  }
  const icon = (signature: DocumentSignature) => {
    switch (signature.status) {
      case 'Pending' :
        return 'alarm'
    }
    
    return 'done'
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const targetDayMonth = date.toLocaleDateString('default', {
      day: '2-digit',
      month: 'short'
    })
    const targetYear = date.toLocaleDateString('default', {year: '2-digit'})
    
    return targetDayMonth + ' \'' + targetYear
  }

  let signatures = [
    {
      email : project.fields['internal_signatory_email'],
      status: 'Pending'
    },
    {
      email : project.fields['artist_signatory_email'],
      status: 'Done'
    }
  ]

  return <SidePanel heading="Signatures">
    <table className="w-full">{signatures.map((signature: any) => {
      return <tr className="mb-2">
        <td>{signature.email}</td>
        <td className={'flex items-center ' + color(signature)} style={{width: '100px'}}>
          <Icon name={icon(signature)} size={5} className="mr-2"/>
          <span>{signature.status}</span></td>
        {signature.signed_at && <td title={signature.signed_at}>{formatDate(signature.signed_at)}</td>}
        {signature.status === 'Pending' && <td className="underline text-gray-500">remind</td>}
      </tr>
    })}</table>
  </SidePanel>
}
