import * as React from 'react'
// import {Select} from 'components/select';
import {Dropdown} from 'components/Dropdown'

interface IYearSelectProps {
    value: number | null;
    onChange: (year: number | null) => void;
    placeholder?: string;
    from?: number;
    to?: number
}

const MonthSelect: React.FC<IYearSelectProps> = ({value, onChange, placeholder, from = 1, to = 12}) => {
  const options = React.useMemo(() => {
    const options = []
    let month = from
    while (month <= to) {
      options.push({
        value: month,
        label: String(month)
      })
      month++
    }

    return options
  }, [from, to])

  return (
    <Dropdown
      name={String(Math.random())}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      autocomplete={true}
    />
  )
}

export default MonthSelect
