import React, {FC, useState, useCallback, useRef, useEffect, useContext} from 'react'
import DayPicker, {DayModifiers} from 'react-day-picker'
import {format} from 'date-fns'
import {Icon} from './Icon'
import {Dropdown} from './Dropdown'
import {TextInput} from './TextInput'
import {Popper, Manager, Reference} from 'react-popper'
import {createPortal} from 'react-dom'
import range from 'lodash/range'

import './DatePicker.css'
import {AppContext} from 'contexts'
import moment from 'moment'

const KEY_BACKSPACE = 8
const KEY_ESCAPE = 27

interface Props {
    name: string;
    value: string | null;
    placeholder?: string;
    onChange: (date: string | null) => void;
    since?: string;
    until?: string;
    hasError?: boolean;
    formatInput?: string | ((date: string) => string);
    placement?:
        | 'auto-start'
        | 'auto'
        | 'auto-end'
        | 'top-start'
        | 'top'
        | 'top-end'
        | 'right-start'
        | 'right'
        | 'right-end'
        | 'bottom-end'
        | 'bottom'
        | 'bottom-start'
        | 'left-end'
        | 'left'
        | 'left-start';
}

export const DatePicker: FC<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  since,
  until,
  formatInput,
  hasError = false,
  placement = 'bottom-start'
}) => {
  const [open, setOpen] = useState(false)

  const popperRef = useRef<HTMLElement>(null)
  const handleRef = useRef<HTMLElement>(null)

  const handleOutsideClick = useCallback((event: Event) => {
    // This condition is a kind of a hack. When focusing an input,
    // a "click" event with <body> for `target` is also dispatched.
    if (event.target === document.body) {
      return
    }

    if (
      popperRef.current?.contains(event.target as Node) ||
      handleRef.current?.contains(event.target as Node)
    ) {
      return
    }

    setOpen(false)
  }, [])

  useEffect(() => {
    if (open) {
      document.body.addEventListener('click', handleOutsideClick)
    } else {
      document.body.removeEventListener('click', handleOutsideClick)
    }

    return () => document.body.removeEventListener('click', handleOutsideClick)
  }, [open, handleOutsideClick])

  const getDate = (value: string) => {
    const date = new Date(value)
    let userTimezoneOffset = date.getTimezoneOffset() * 60000

    return new Date(date.getTime() - userTimezoneOffset)
  }

  function formatValue(value: null | string): string {
    if (!value) {
      return ''
    }

    if (!formatInput) {
      return value
    }

    if (typeof formatInput === 'function') {
      return formatInput(value)
    }

    return format(getDate(value), formatInput)
  }

  return (
    <Manager>
      <Reference innerRef={handleRef}>
        {({ref}) => (
          <div ref={ref}>
            <TextInput
              name={name}
              placeholder={placeholder}
              icon="date_range"
              defaultValue={formatValue(value)}
              readOnly={true}
              onFocus={() => setOpen(true)}
              onKeyDown={(e) => {
                if (e.keyCode === KEY_BACKSPACE || e.keyCode === KEY_ESCAPE) {
                  onChange(null)
                }
              }}
              hasError={hasError}
            />
          </div>
        )}
      </Reference>
      {open && createPortal(
        <Popper
          innerRef={popperRef}
          placement={placement}
          modifiers={{
            flip: {enabled: false},
            preventOverflow: {enabled: false}
          }}
        >
          {({ref, style}) => (
            <div ref={ref} style={style} data-cy={`date-picker-${name}`}>
              <DatePickerInner
                value={value ? getDate(value) : null}
                onChange={(date) => {
                  console.log('DatePickerInner', date, date && format(date, 'y-MM-dd'))
                  if (date && since) {
                    if (moment(date) < moment(since)) {
                      return
                    }
                  }
                  onChange(date ? format(date, 'y-MM-dd') : null)
                  setOpen(false)
                }}
                since={since}
                until={until}
              />
            </div>
          )}
        </Popper>,
        document.body
      )}
    </Manager>
  )
}

function DatePickerInner({
  value,
  onChange,
  since,
  until
}: {
    value: Date | null;
    onChange: (value: Date | null) => void;
    since?: string;
    until?: string;
}) {
  const [month, setMonth] = useState((value || new Date()).getMonth())
  const [year, setYear] = useState((value || new Date()).getFullYear())
  const {config} = useContext(AppContext)
  const {first_day: firstDayOfWeek} = config
  console.log('DatePickerInnerSince', since, moment(since).toDate())

  return (
    <DayPicker
      disabledDays={since ? [
        {
          after: new Date(1970, 0, 1),
          before: moment(since).toDate()
        }
      ] : []}
      month={new Date(year, month)}
      onMonthChange={(date) => {
        setMonth(date.getMonth())
        setYear(date.getFullYear())
      }}
      firstDayOfWeek={firstDayOfWeek}
      showOutsideDays={true}
      fixedWeeks={true}
      selectedDays={value || []}
      classNames={classNames}
      renderDay={(date, modifiers: DayModifiers) => {
        console.log('DayModifiers', modifiers)

        return <Day date={date}
          modifiers={{today: modifiers.today, outside: modifiers.outside, selected: modifiers.selected, disabled: modifiers.disabled}}/>
      }}
      weekdayElement={({weekday, className, localeUtils}) => {
        return (
          <abbr
            title={localeUtils.formatWeekdayLong(weekday)}
            style={{textDecoration: 'none'}}
            className={className}
          >
            {localeUtils.formatWeekdayShort(weekday)}
          </abbr>
        )
      }}
      captionElement={() => (
        <div className="-mt-13">
          <YearMonthPicker
            month={month}
            year={year}
            onMonthChange={setMonth}
            onYearChange={setYear}
            since={since ? new Date(since) : undefined}
            until={until ? new Date(until) : undefined}
          />
        </div>
      )}
      navbarElement={({onNextClick, onPreviousClick, labels}) => (
        <PrevNextButtons
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          labels={labels}
        />
      )}
      onDayClick={onChange}
    />
  )
}

const Day: FC<{
    date: Date;
    modifiers: DayModifiers;
}> = ({date, modifiers}) => {
  const className = {
    outside: 'w-12 h-12 text-gray-500',
    disabled: 'w-12 h-12 text-gray-300',
    selected: 'w-12 h-12 bg-blue-700 text-white rounded',
    normal: 'w-12 h-12 hover:bg-blue-100 rounded',
    today: 'w-12 h-12 text-blue-700'
  }[dayType(modifiers)]

  return (
    <div className={`flex items-center justify-center cursor-pointer ${className}`}>
      {format(date, 'd')}
    </div>
  )
}

function dayType(modifiers: DayModifiers): 'normal' | 'selected' | 'outside' | 'today' | 'disabled' {
  if (modifiers.selected) {
    return 'selected'
  }

  if (modifiers.outside || modifiers.disabled) {
    return 'outside'
  }

  if (modifiers.today) {
    return 'today'
  }

  return 'normal'
}

const PrevNextButtons: FC<{
    onPreviousClick: () => void;
    onNextClick: () => void;
    labels: {previousMonth: string; nextMonth: string};
}> = ({onPreviousClick, onNextClick, labels}) => {
  return (
    <div className="flex items-center justify-between py-3">
      <button
        onClick={() => onPreviousClick()}
        className="cursor-pointer focus:outline-none"
        aria-label={labels.previousMonth}
      >
        <Icon name="keyboard_arrow_left" size={6} className="text-gray-600" />
      </button>

      <button
        onClick={() => onNextClick()}
        className="cursor-pointer focus:outline-none"
        aria-label={labels.nextMonth}
      >
        <Icon name="keyboard_arrow_right" size={6} className="text-gray-600" />
      </button>
    </div>
  )
}

const YearMonthPicker: FC<{
    month: number;
    onMonthChange: (value: number) => void;
    year: number;
    onYearChange: (value: number) => void;
    since?: Date;
    until?: Date;
}> = ({month, onMonthChange, year, onYearChange, since, until}) => {
  since = since || new Date(year - 3 - 1, month)
  until = until || new Date(year + 3, month)

  const years = range(until.getFullYear(), since.getFullYear()).map((year) => ({
    label: String(year),
    value: year
  }))

  return (
    <div className="flex items-center justify-center py-3">
      <div className="mx-2">
        <Dropdown
          name="month"
          value={month}
          options={[
            {label: 'January', value: 0},
            {label: 'February', value: 1},
            {label: 'March', value: 2},
            {label: 'April', value: 3},
            {label: 'May', value: 4},
            {label: 'June', value: 5},
            {label: 'July', value: 6},
            {label: 'August', value: 7},
            {label: 'September', value: 8},
            {label: 'October', value: 9},
            {label: 'November', value: 10},
            {label: 'December', value: 11}
          ]}
          renderInput={({selectedItem, toggleMenu}) => {
            return (
              <div
                onClick={toggleMenu}
                className="relative flex items-center cursor-pointer"
              >
                <span>{selectedItem?.label}</span>
                <Icon name="keyboard_arrow_down" size={4} className="ml-1" />
              </div>
            )
          }}
          onChange={onMonthChange}
          usePortal={false}
          dropdownPlacement="bottom"
        />
      </div>

      <div className="mx-2">
        <Dropdown
          name="year"
          value={year}
          options={years}
          renderInput={({selectedItem, toggleMenu}) => {
            return (
              <div
                onClick={toggleMenu}
                className="relative flex items-center cursor-pointer"
              >
                <span>{selectedItem?.label}</span>
                <Icon name="keyboard_arrow_down" size={4} className="ml-1" />
              </div>
            )
          }}
          onChange={onYearChange}
          usePortal={false}
          dropdownPlacement="bottom"
        />
      </div>
    </div>
  )
}

const classNames = {
  container: 'bg-white shadow-lg rounded-lg p-6', // The container element
  wrapper: 'select-none focus:outline-none', // The wrapper element, used for keyboard interaction
  interactionDisabled: '', // Added to the container when there's no interaction with the calendar

  navBar: '', // The navigation bar with the arrows to switch between months
  navButtonPrev: '', // Button to switch to the previous month
  navButtonNext: '', // Button to switch to the next month
  navButtonInteractionDisabled: '', // Added to the navbuttons when disabled with fromMonth/toMonth props

  months: '', // Container of the months table
  month: '', // The month's main table
  caption: '', // The caption element, containing the current month's name and year
  weekdays: '', // Table header displaying the weekdays names
  weekdaysRow: 'flex -mx-2', // Table row displaying the weekdays names
  weekday: 'w-12 h-12 mx-2 flex items-center justify-center text-gray-600', // Cell displaying the weekday name
  body: '', // Table's body with the weeks
  week: 'flex -mx-2', // Table's row for each week
  day: 'day-focus mx-2 rounded', // The single day cell
  weekNumber: '',

  footer: '', // The calendar footer (only with todayButton prop)
  todayButton: '', // The today button (only with todayButton prop)

  /* default modifiers */
  today: 'today', // Added to the day's cell for the current day
  selected: 'selected', // Added to the day's cell specified in the "selectedDays" prop
  disabled: 'disabled', // Added to the day's cell specified in the "disabledDays" prop
  outside: 'outside' // Added to the day's cell outside the current month
}
