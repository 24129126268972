import {Project} from '@contractool/schema'
import * as React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {Context as ReportContext} from 'contexts/reports/tab/report'
import {Modal} from 'components/Modal'
import {ProjectsList} from 'views/projects/Grid/ProjectsList'
import {http} from 'utils/http'
import {translate} from 'utils/translations'

const ProjectListOverlay = () => {
  const {config} = React.useContext(ReportContext)
  const history = useHistory()
  const location = useLocation()

  const [projects, setProjects] = React.useState<Project[]>([])
  React.useEffect(() => {
    if (location.search) {
      const name = decodeURIComponent(location.search.split('=')[1])
      http.post<Project[]>('/api/reports/generic-list', {
        ...config,
        clicked: {
          name
        }
      }).then((result) => {
        setProjects(result.data)
      })
    }
    // eslint-disable-next-line
    }, []);

  const handleClose = () => {
    // this is hotfix, should refactor
    history.goBack()
    // history.push('/reports');
  }

  return (
    <Modal
      heading="Report detail"
      compact={true}
      corner={
        <div onClick={handleClose} className="cursor-pointer text-blue-500">
          {translate('Close')}
        </div>
      }
      size="w-full"
      onClose={handleClose}
    >
      <ProjectsList
        projects={projects}
        onRefresh={() => {}}
        loaded={true}
        onFavorite={() => {}}
      />
    </Modal>
  )
}

export default ProjectListOverlay
