import React, {ReactNode, FC} from 'react'
import {Icon} from './Icon'

export const Checkbox: FC<{
    name: string;
    value: string;
    selectedValues: string[];
    onChange: (values: string[]) => void;
    children?: ReactNode;
    className?: string;
    childsClassName?: string;
}> = ({name, value, selectedValues, children, className, childsClassName, onChange}) => {
  const checked = selectedValues.includes(value)

  return (
    <label
      className={`cursor-pointer flex items-center select-none ${className}`}
      htmlFor={value}
    >
      <input
        type="checkbox"
        id={value}
        name={name}
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) {
            onChange([...selectedValues, value])
          } else {
            onChange(selectedValues.filter((v) => v !== value))
          }
        }}
        className="hidden"
      />

      <div
        className={`w-6 h-6 rounded flex justify-center items-center p-1 border ${
          checked
            ? 'bg-blue-700 hover:bg-blue-800'
            : 'border-gray-200 hover:border-blue-700 bg-white'
        }`}
      >
        {checked && <Icon name="done" className="text-white" size={4} />}
      </div>

      {children && <div className={`pl-3 ${childsClassName}`}>{children}</div>}
    </label>
  )
}
