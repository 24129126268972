import React from 'react'
import {Task, Project} from '@contractool/schema'
import {Form} from '../../components/Form'
import {http} from '../../utils/http'
import {translate} from 'utils/translations'
import {Modal} from '../../components/Modal'
import {Button} from '../../components/Button'
import {useToasts} from 'hooks'
import {useParams} from 'react-router-dom'

export function TaskEdit({
  project,
  onUpdate,
  onClose
}: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}) {
  const {success} = useToasts()
  const {taskId} = useParams<{taskId?: string}>()

  const task = project.tasks.find((t) => taskId && t.id === parseInt(taskId))
  if (!task) return null
  task.deadline = task.deadline.replace(/ .*/, '') //This removes time from datetime

  return (
    <Modal heading={translate('Edit task')} onClose={onClose}>
      <Form
        initialValues={{...task}}
        onSubmit={(values) => {
          values.deadline += ' 00:00:00'

          return http.put<Task>(task.url, values)
        }}
        onSuccess={(task: Task) => {
          onClose()
          onUpdate()
          success(
            `${translate('Task :title was updated successfully', {title: task.title})}`
          )
        }}
      >
        <Form.TextInput
          name="title"
          label={translate('Title')}
          className="mb-6"
          autoFocus={true}
        />
        <Form.DatePicker
          name="deadline"
          label={translate('Due date')}
          className="mb-6"
          formatInput="dd MMM yyyy"
        />
        <Form.UserDropdown
          api="api/users"
          name="assignee_id"
          label={translate('Assignee')}
          className="mb-6"
          autocomplete
        />
        <Form.TextArea
          name="body"
          label={translate('Description')}
          rows={6}
          className="mb-6"
        />

        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onClose}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Save Changes')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
