import React, {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  KeyboardEvent
} from 'react'
import {useHistory} from 'react-router-dom'
import {CurrencyInput} from 'components/form/inputs'
import MonthlySavings from 'components/MonthlySavings'
import {cx} from 'utils/classnames'
import {Field} from './Field'
import {Modal} from './Modal'
import {Button, Color} from './Button'
import {Switch} from './Switch'
import {Message} from './Message'
import {DatePicker} from './DatePicker'
import {TextInput, Type} from './TextInput'
import {Dropdown, Option} from './Dropdown'
import {TextArea, TextAreaProps} from './TextArea'
import {Response} from 'utils/http'
import {MultiselectDropdown} from './MultiselectDropdown'
import {EventEmitter} from 'utils/eventEmitter'
import {translate} from 'utils/translations'
import {useToasts} from 'hooks'
import {AdvancedRadioValue, FieldTemplate} from '@contractool/schema'
import {BigRadioBox, RadioBoxContainer, SmallRadioBox} from './RadioBox'
import {useSmallLoader, useBigLoader} from './Loader'
import {Editor} from '@tinymce/tinymce-react'
import FileUpload from 'components/form/FileUpload'

interface Params<T> {
    values: T;

    errors: Partial<Record<keyof T, string[]>>;
    hasError: (name: keyof T) => boolean;
    firstError: (name: keyof T) => string | undefined;

    addField: (name: string, content: any) => void;
    removeField: (name: string, index: number) => void;

    handleRoleChange: <F extends keyof T>(
        name: string,
        value: T[F],
        role?: {key: string; value: string},
        singular?: boolean,
    ) => void;

    handleGenChange: <F extends keyof T>(
        name: string,
        value: T[F],
        target: string,
        dependencies: string[],
    ) => void;

    handleOptions: (
        dependencies: string[],
        options: Record<string, Record<string, Option<any>[]>>,
        alternative?: string[],
    ) => Option<any>[];

    handleChangeDepend: <F extends keyof T>(
        name: string,
        value: T[F],
        dependencies: string[],
    ) => void;

    handleChange: <F extends keyof T>(name: F, value: T[F]) => void;
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, mode?: string) => void;

    dirty: boolean; // any values have been changed
    disabled?: boolean; // any values have been changed
    allowPristineSubmission: boolean; // allow to click submit even when no values have been changed
    isLoading: boolean;
    invalid: boolean,
    markInvalid: (value: boolean) => void,
}

type Values = Record<string, any>;

/**
 * INTERNAL USE ONLY (internal = in this file)
 * Not typed properly as the types depend on <Form>.
 */
export const FormContext = createContext<Params<Values>>({
  values: {},
  isLoading: false,
  errors: {},
  hasError: () => false,
  firstError: () => undefined,
  addField: () => {},
  removeField: () => {},
  handleGenChange: () => {},
  handleRoleChange: () => {},
  handleOptions: () => [],
  handleChangeDepend: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
  dirty: false,
  allowPristineSubmission: false,
  invalid: false,
  markInvalid: () => {}
})

export function Form<T extends Values>({
  initialValues,

  onSubmit,
  onSuccess,
  onError,

  submitOnEnter = false,

  children,

  name = '',
  guard: guardEnabled = true,
  allowPristineSubmission = false,
  clearAtSuccess = false,
  loader,
  loaderText,
  supportsSubmitMode = false
}: {
    initialValues: T;

    onSubmit: (values: T, mode?: string) => Promise<Response<any>>;
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;

    submitOnEnter?: boolean;

    children: ReactNode;
    name?: string;
    allowPristineSubmission?: boolean;
    guard?: boolean;
    clearAtSuccess?: boolean;
    loader?: 'big' | 'small';
    loaderText?: string;
    supportsSubmitMode?: boolean;
}) {
  const history = useHistory()
  const {error} = useToasts()
  const [values, setValues] = useState({...initialValues})
  const [errors, setErrors] = useState<Params<T>['errors']>({})
  const [dirty, setDirty] = useState(false)
  const [pathGuard, setPathGuard] = useState<string | null>(null)
  const [searchGuard, setSearchGuard] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const smallLoader = useSmallLoader()
  const bigLoader = useBigLoader()

  EventEmitter.subscribe(name + '.set-form-values', (newValues: any) => {
    setValues({...values, ...newValues})
  })

  useEffect(() => {
    const toggleLoader = (loader: 'big' | 'small', isLoading: boolean) => {
      if (loader === 'big') {
        isLoading
          ? bigLoader.start(loaderText ? loaderText : `${translate('Wait a moment')}...`)
          : bigLoader.stop()
      } else {
        isLoading
          ? smallLoader.start(loaderText ? loaderText : `${translate('Wait a moment')}...`)
          : smallLoader.stop()
      }
    }

    if (loader) {
      toggleLoader(loader, isLoading)
    }

    return history.block((arg) => {
      // if guard is not enabled, do not block
      if (!guardEnabled) {
        return
      }

      // do not block when not touched
      if (!dirty) {
        return
      }

      // do not block when going to the same route
      if (arg.pathname === history.location.pathname) {
        return
      }

      if (arg.pathname.startsWith(history.location.pathname)) {
        return
      }

      // do not block if `pathname === guard`
      // because this means the user clicked
      // "Yes, close" in the guard modal
      if (arg.pathname === pathGuard) {
        return
      }

      setPathGuard(arg.pathname)
      setSearchGuard(arg.search ?? '')

      return false
    })
  }, [
    history,
    dirty,
    pathGuard,
    guardEnabled,
    isLoading,
    loader,
    bigLoader,
    smallLoader,
    loaderText
  ])

  const submit = React.useCallback((mode?: string) => {
    setIsLoading(true);
    (supportsSubmitMode ? onSubmit(values, mode) : onSubmit(values))
      .then((response) => {
        setDirty(false)
        setErrors({})
        setIsLoading(false)

        if (onSuccess) {
          onSuccess(response.data)
          clearAtSuccess && setValues(initialValues)
        }
      })
      .catch((e) => {
        console.log('form error', e)
        error(`${translate('Please check validation errors')}`)
        const errors = e?.response?.data?.errors || {}
        // support array, todo: better solution
        for (let key in errors) {
          const segments = key.split('.')
          if (segments.length > 1 && !isNaN(parseInt(segments[1]))) {
            const newKey = segments.join('|')
            errors[newKey] = errors[key]
            delete errors[key]
          }
        }
        setErrors(errors)
        setIsLoading(false)
        if (onError) {
          onError(e?.response?.data?.errors)
        }
      })
  }, [clearAtSuccess, error, initialValues, onError, onSubmit, onSuccess, values, supportsSubmitMode])

  const [invalid, setInvalid] = React.useState(false)
  const markInvalid = React.useCallback((value) => {
    setInvalid(value)
  }, [])

  const contextValue = React.useMemo(() => {
    return {
      values,
      isLoading,
      errors,

      hasError(name: string) {
        return errors[name] !== undefined
      },

      firstError(name: string) {
        return errors[name]?.[0]
      },

      addField(name: string, content: any) {
        setValues({...values, [name]: [...values[name], content]})
      },

      removeField(name: string, id: number) {
        setValues({
          ...values,
          [name]: [...values[name]].filter((_, index) => id !== index)
        })
      },

      handleRoleChange(name: string, value: any, role?: any, singular = true) {
        setDirty(true)
        const keys = name.split('.')

        if (role && singular) {
          setValues({
            ...values,
            team: {
              ...values.team,
              singular: {
                ...values.team.singular,
                [keys[2]]: {
                  ...values.team.singular[keys[2]],
                  user_id: value,
                  [role.key]: role.value
                }
              }
            }
          })
        } else if (role && !singular) {
          setValues({
            ...values,
            team: {
              ...values.team,
              multiple: {
                ...values.team.multiple,
                [keys[2]]: [...value]
              }
            }
          })
        } else {
          setValues({...values, [name]: value})
        }
      },

      handleChangeDepend(name: string, value: any, dependencies: any) {
        setDirty(true)

        let newValues = {
          ...values
        }

        for (const name of dependencies) {
          if (name.includes('.')) {
            const keys = name.split('.')

            newValues = {
              ...newValues,
              [keys[0]]: {
                ...newValues[keys[0]],
                [keys[1]]: ''
              }
            }
          } else {
            newValues = {...newValues, [name]: ''}
          }
        }

        if (name.includes('.')) {
          const keys = name.split('.')

          setValues({
            ...newValues,
            [keys[0]]: {
              ...newValues[keys[0]],
              [keys[1]]: value
            }
          })
        } else {
          setValues({...newValues, [name]: value})
        }
      },

      handleGenChange(name: string, value: any, target: any, dependencies: any) {
        setDirty(true)
        if (name.includes('.')) {
          const keys = name.split('.')
          if (keys[0] === 'team' && keys[1] === 'singular') {
            const newValues = {
              ...values,
              [keys[0]]: {
                ...values[keys[0]],
                [keys[1]]: {
                  ...values[keys[0]][keys[1]],
                  [keys[2]]: {
                    ...values[keys[0]][keys[1]][keys[2]],
                    [keys[3]]: value,
                    [keys[4]]: keys[2]
                  }
                }
              }
            }

            const newTarget = autogenerate(newValues, dependencies)
            setValues({...newValues, [target]: newTarget})
          }
          const newValues = {
            ...values,
            [keys[0]]: {
              ...values[keys[0]],
              [keys[1]]: value
            }
          }

          const newTarget = autogenerate(newValues, dependencies)
          setValues({...newValues, [target]: newTarget})
        } else if (name.includes('|')) {
          const keys = name.split('|')

          const nArr = [...values[keys[0]]]
          nArr[parseInt(keys[1])] = {
            ...nArr[parseInt(keys[1])],
            [keys[2]]: value
          }

          setValues({
            ...values,
            [keys[0]]: nArr
          })
        } else {
          setValues({...values, [name]: value})
        }
      },

      handleOptions(dependencies: any, options: any, alt: any) {
        let depends: string[] = []
        for (const dep of dependencies) {
          let val = values[dep]
          if (dep.includes('.')) {
            const keys = dep.split('.')
            val = values[keys[0]][keys[1]]
          }
          depends.push(val)
        }

        depends[1] = alt && depends[1] === alt[0] ? alt[1] : depends[1]

        const opt: Option<any>[] =
                    depends[0] && depends[1] ? options[depends[0]][depends[1]] : []

        return opt
      },

      handleChange(name: string, value: any) {
        setDirty(true)

        if (name.includes('.')) {
          const keys = name.split('.')
          if (keys[0] === 'team' && keys[1] === 'singular') {
            setValues({
              ...values,
              [keys[0]]: {
                ...values[keys[0]],
                [keys[1]]: {
                  ...values[keys[0]][keys[1]],
                  [keys[2]]: {
                    ...values[keys[0]][keys[1]][keys[2]],
                    [keys[3]]: value,
                    [keys[4]]: keys[2]
                  }
                }
              }
            })
          } else {
            setValues({
              ...values,
              [keys[0]]: {
                ...values[keys[0]],
                [keys[1]]: value
              }
            })
          }
        } else if (name.includes('|')) {
          const keys = name.split('|')

          const nArr = [...values[keys[0]]]
          nArr[parseInt(keys[1])] = {
            ...nArr[parseInt(keys[1])],
            [keys[2]]: value
          }

          setValues({
            ...values,
            [keys[0]]: nArr
          })
        } else {
          // @ts-ignore
          values[name] = value
          // this values[name] = value; line is here because sometimes more than 2 fields are updated between single render when fieldChange is called directly
          setValues({...values, [name]: value})
        }
      },

      handleSubmit(event: any, mode?: string) {
        event.preventDefault()

        submit(mode)
      },

      dirty,
      allowPristineSubmission,
      invalid,
      markInvalid
    }
  }, [allowPristineSubmission, dirty, errors, isLoading, submit, values, invalid, markInvalid])

  return (
    <>
      {pathGuard && (
        <Modal
          isOpen={true}
          onClose={() => {}}
          heading={translate('Unsaved changes')}
          size="small"
        >
          <Message.Warning>
            {translate(
              'You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to leave this page?'
            )}
          </Message.Warning>

          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={() => {
              setPathGuard(null)
              setSearchGuard('')
            }}>
              {translate('Cancel')}
            </Button>
            <Button color="yellow" onClick={() => (pathGuard ? history.push(pathGuard + searchGuard) : null)}>
              {translate('Yes, leave')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault()

          if (submitOnEnter) {
            submit()
          }
        }}
      >
        <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
      </form>
    </>
  )
}

export const getValue = (values: any, name: string, isArray: boolean = false): any | any[] => {
  if (name.includes('.')) {
    const keys = name.split('.')
    if (keys[0] === 'team' && keys[1] === 'singular') {
      return values[keys[0]][keys[1]][keys[2]]
        ? values[keys[0]][keys[1]][keys[2]]
        : isArray
          ? []
          : ''
    } else if (keys[0] === 'team' && keys[1] === 'multiple') {
      return values[keys[0]][keys[1]][keys[2]]
        ? values[keys[0]][keys[1]][keys[2]]
        : isArray
          ? []
          : ''
    }

    return values[keys[0]][keys[1]] ? values[keys[0]][keys[1]] : isArray ? [] : ''
  } else if (name.includes('|')) {
    const keys = name.split('|')

    return values[keys[0]][keys[1]][keys[2]]
      ? values[keys[0]][keys[1]][keys[2]]
      : isArray
        ? []
        : ''
  }

  return values[name] ? values[name] : isArray ? [] : ''
}

const autogenerate = (values: any, dependencies: string[]): string => {
  let result: string = ''
  for (const dep of dependencies) {
    result = getValue(values, dep) === '' ? result : result.concat(`${getValue(values, dep)}_`)
  }

  return result.length === 0 ? result : result.slice(0, -1)
}

const Form_TextInput: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    placeholder?: string;
    type?: Type;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    right?: ReactNode;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  name,
  label,
  legend,
  helptext,
  type = 'text',
  required = false,
  placeholder,
  className,
  autoFocus,
  onKeyDown,
  disabled,
  right
}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleChange}) => {
        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            right={right}
            errorMessage={firstError(name)}
            className={className}
            required={required}
          >
            <TextInput
              name={name}
              type={type}
              disabled={disabled}
              value={getValue(values, name)}
              onChange={(v) => {
                EventEmitter.dispatch('textinput.' + name, v)
                handleChange(name, v)
              }}
              hasError={hasError(name)}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onKeyDown={onKeyDown}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_UnifiedField: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    className?: string;
    required?: boolean;
    component: React.ComponentType | React.ElementType;
    right?: ReactNode;
    [key: string]: any;
}> = ({name, label,legend, helptext, className, component: Component, right, required = false, ...restProps}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleChange}) => {
        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
            right={right}
            required={required}
          >
            <Component
              name={name}
              value={getValue(values, name)}
              hasError={hasError(name)}
              onChange={(v: string) => {
                handleChange(name, v)
              }}
              {...restProps}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_CurrencyInput: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext? : string;
    locale?: string;
    currency?: string;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  name,
  label,
  legend,
  helptext,
  locale = 'en-US',
  currency = 'USD',
  placeholder,
  className,
  autoFocus,
  onKeyDown,
  disabled,
  required = false
}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleChange}) => {
        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
            required={required}
          >
            <CurrencyInput
              name={name}
              className={`py-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 ${
                hasError(name)
                  ? 'border-red-700'
                  : 'border-gray-200 focus:border-blue-700'
              }`}
              disabled={disabled}
              value={getValue(values, name)}
              onChange={(v) => {
                EventEmitter.dispatch('textinput.' + name, v)
                handleChange(name, v)
              }}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onKeyDown={onKeyDown}
              locale={locale}
              currency={currency}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_Dropdown: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    options?: Option<any>[];
    api?: string;
    className?: string;
    right?: ReactNode;
    autocomplete?: boolean;
    onChange?: (value: any) => void;
    required?: boolean;
    clearDependencies?: string[];
}> = ({
  name,
  label,
  legend,
  helptext,
  options,
  api,
  className,
  right,
  autocomplete,
  required = false,
  onChange,
  clearDependencies
}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleChange, handleChangeDepend}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          errorMessage={firstError(name)}
          className={className}
          right={right}
          required={required}
        >
          <Dropdown
            name={name}
            value={getValue(values, name)}
            options={options}
            api={api}
            onChange={(v) => {
              clearDependencies
                ? handleChangeDepend(name, v, clearDependencies)
                : handleChange(name, v)
              if (onChange) {
                onChange(v)
              }
              const val = options?.find((option) => option.value === v)
              EventEmitter.dispatch('dropdown.' + name, val ? val.label : '')
            }}
            hasError={hasError(name)}
            autocomplete={autocomplete}
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_Switch_Multiple: <T>(props: {
    name: string;
    label: string;
    items: T[];
    toKey: (x: T) => string;
    className?: string;
    children: (item: T, element: FC) => ReactElement;
}) => ReactElement = ({children, name, label, items, toKey, className}) => {
  return (
    <FormContext.Consumer>
      {({values, firstError, handleChange}) => (
        <Field name={name} label={label} errorMessage={firstError(name)}>
          <Switch.Multiple
            name={name}
            value={values[name]}
            items={items}
            toKey={toKey}
            onChange={(values) => handleChange(name, values)}
            className={className}
          >
            {children}
          </Switch.Multiple>
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_DatePicker: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    className?: string;
    placeholder?: string;
    since?: string;
    until?: string;
    required?: boolean;
    formatInput?: string | ((date: string) => string);
}> = ({name, label, legend, helptext, className, placeholder, required = false, since, until, formatInput}) => {
  return (
    <FormContext.Consumer>
      {({values, firstError, hasError, handleChange}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          errorMessage={firstError(name)}
          className={className}
          required={required}
        >
          <DatePicker
            name={name}
            value={getValue(values, name)}
            onChange={(value) => {
              EventEmitter.dispatch('datepicker.' + name, value)
              handleChange(name, value)
            }}
            since={since}
            until={until}
            formatInput={formatInput}
            placeholder={placeholder}
            hasError={hasError(name)}
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_TextArea: FC<
    Omit<TextAreaProps, 'onChange' | 'value' | 'defaultValue'> & {
        label: string;
        legend?: string;
        helptext?: string;
        className?: string;
    }
> = ({name, className, label, legend, helptext, required = false, ...rest}) => {
  return (
    <FormContext.Consumer>
      {({values, firstError, hasError, handleChange}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          errorMessage={firstError(name)}
          className={className}
          required={required}
        >
          <TextArea
            name={name}
            value={getValue(values, name)}
            onChange={(value) => handleChange(name, value)}
            hasError={hasError(name)}
            {...rest}
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}
const Form_Wysiwyg: FC<
    Omit<TextAreaProps, 'onChange' | 'value' | 'defaultValue'> & {
        label: string;
        legend?: string;
        className?: string;
    }
> = ({name, className, label, legend, required = false}) => {

  const EDITOR_API_KEY = 'rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0'
  const init = {
    content_style: '.mce-content-body {padding : 7px}',
    height: 500,
    outerWidth: 800,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help'
  }

  return (
    <FormContext.Consumer>
      {({values, firstError, handleChange}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          errorMessage={firstError(name)}
          className={className}
          required={required}
        >
          <Editor
            value={getValue(values, name)}
            apiKey={EDITOR_API_KEY}
            init={init}
            id={'news-editor'}
            onEditorChange={(value) => {
              console.log('onEditorChange', name, value)
              handleChange(name, value)
            }}
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_Submit: FC<{
    children: ReactNode
    color?: Color,
    className?: string
    mode?: string // bad practice
}> = ({children, className, mode, color}) => {
  return (
    <FormContext.Consumer>
      {({handleSubmit, isLoading, dirty, allowPristineSubmission, invalid}) => (
        <Button
          color={color}
          type="submit"
          onClick={e => handleSubmit(e, mode)}
          className={className}
          disabled={(!allowPristineSubmission && !dirty) || isLoading || invalid}
        >
          {children}
        </Button>
      )}
    </FormContext.Consumer>
  )
}

const Form_Dropdown_Multiselect: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    options: Option<any>[];
    placeholder: string;
    className?: string;
    required?: boolean;
    dropdownClassName?: string;
    autocomplete?: boolean;
    changeKeys?: {
        key1: string;
        key2: string;
    };
}> = ({
  name,
  label,
  legend,
  helptext,
  options,
  className,
  placeholder,
  autocomplete = false,
  required = false,
  changeKeys
}) => {
  return (
    <FormContext.Consumer>
      {({firstError, handleChange, values}) => {
        const ids = getValue(values, name, true).map((valueOption: any) => valueOption[changeKeys?.key1 || 'value'])

        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
            required={required}
          >
            <MultiselectDropdown
              name={name}
              values={ids}
              options={options}
              placeholder={placeholder}
              onChange={(options) => {
                const optionsModified = changeKeys
                  ? options.map((option) => ({
                    [changeKeys.key1]: option.value,
                    [changeKeys.key2]: option.label
                  }))
                  : options
                EventEmitter.dispatch('multiselect.' + name, optionsModified)
                handleChange(name, optionsModified)
              }}
              useSearch={autocomplete}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_Attachment_Upload: FC<{
    className?: string;
    field: FieldTemplate
}> = ({className, field}) => {
  return (
    <FormContext.Consumer>
      {({firstError, values}) => (
        <Field
          name={field.name}
          label={field.label}
          required={field.required}
          legend={field.legend ? field.legend : ''}
          helptext={field.helptext ? field.helptext : ''}
          errorMessage={firstError(field.name)}
          className={className}
        >
          <FileUpload field={field} formValues={values}/>
        </Field>
      )}
    </FormContext.Consumer>
  )
}
const Form_Switch_Single: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    className?: string;
}> = ({name, label, legend, helptext, className}) => {
  return (
    <FormContext.Consumer>
      {({values, firstError, handleChange}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          errorMessage={firstError(name)}
          className={className}
        >
          <Switch
            name={name}
            value={getValue(values, name)}
            onChange={(v) => handleChange(name, v)}
            className="flex flex-wrap py-4"
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_DependingDropdown: FC<{
    dependencies: string[];
    alt: string[];
    name: string;
    label: string;
    legend: string;
    helptext: string;
    options: Record<string, Record<string, Option<any>[]>>;
    className?: string | undefined;
    required?: boolean;
}> = ({dependencies, alt, name, label, legend, helptext, options, className, required}) => {
  return (
    <FormContext.Consumer>
      {({handleOptions}) => (
        <Form.Dropdown
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          className={className}
          required={required}
          options={handleOptions(dependencies, options, alt)}
        />
      )}
    </FormContext.Consumer>
  )
}

const Form_GeneratingDropdown: FC<{
    name: string;
    label: string;
    legend: string;
    helptext: string;
    options: Option<any>[];
    className?: string;
    right?: ReactNode;
    generate?: {
        target: string;
        dependencies: string[];
    };
    required?: boolean;
}> = ({name, label, legend, helptext, options, className, required = false, right, generate}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleChange, handleGenChange}) => (
        <Field
          name={name}
          label={label}
          legend={legend}
          helptext={helptext}
          errorMessage={firstError(name)}
          className={className}
          right={right}
          required={required}
        >
          <Dropdown
            name={name}
            value={getValue(values, name)}
            options={options}
            onChange={(v) =>
              generate
                ? handleGenChange(name, v, generate.target, generate.dependencies)
                : handleChange(name, v)
            }
            hasError={hasError(name)}
          />
        </Field>
      )}
    </FormContext.Consumer>
  )
}

const Form_UserDropdown: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    api: string;
    apiParams?: object;
    className?: string;
    right?: ReactNode;
    autocomplete?: boolean;
    role?: {
        key: string;
        value: string;
    };
    required?: boolean;
}> = ({name, label, legend, helptext, api, apiParams, className, right, required = false, autocomplete, role}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleRoleChange}) => {
        const selectedValue = role
          ? getValue(values, name).user_id
          : getValue(values, name)

        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
            right={right}
            required={required}
          >
            <Dropdown
              name={name}
              value={selectedValue}
              api={api}
              apiParams={apiParams}
              onChange={(v) => handleRoleChange(name, v, role)}
              hasError={hasError(name)}
              autocomplete={autocomplete}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_UserMultiselect: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    className?: string;
    right?: ReactNode;
    options: Option<any>[];
    autocomplete?: boolean;
    role?: {
        key: string;
        value: string;
    };
    required?: boolean;
    shownItemSize?: number;
}> = ({
  name,
  label,
  legend,
  helptext,
  role,
  className,
  options,
  right,
  autocomplete,
  shownItemSize = 2,
  required = false
}) => {
  return (
    <FormContext.Consumer>
      {({values, hasError, firstError, handleRoleChange}) => {
        const selectedValues = role
          ? getValue(values, name, true).map((v: {user_id: any}) => v.user_id)
          : getValue(values, name, true)

        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
            right={right}
            required={required}
          >
            <MultiselectDropdown
              name={name}
              values={selectedValues}
              onChange={(v) => {
                handleRoleChange(
                  name,
                  v.map((item) => ({user_id: item.value, role: role?.value})),
                  role,
                  false
                )
              }}
              options={options}
              placeholder={label}
              hasError={hasError(name)}
              useSearch={autocomplete}
              shownItemSize={shownItemSize}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_Formula: FC<{
    name: string;
    label?: string;
    legend?: string;
    helptext: string;
    placeholder?: string;
    type?: Type;
    className?: string;
    formula?: string;
    formulaValue?: any;
}> = ({name, label, legend, helptext, type = 'text', placeholder, className, formula}) => {
  const {values: formValues, handleChange} = React.useContext(FormContext)

  React.useEffect(() => {
    if (formula) {
      let expression = formula
      for (const [key, value] of Object.entries(formValues)) {
        if (expression.indexOf(key) >= 0) {
          let floatValue = parseFloat(value)
          expression = expression.replace(key, floatValue.toString())
        }
      }

      let calculatedValue
      try {
        // eslint-disable-next-line
                calculatedValue = eval(expression);
        if (isNaN(calculatedValue)) {
          calculatedValue = null
        } else {
          calculatedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(calculatedValue)
        }
      } catch (err) {
        calculatedValue = null
      }

      if (formValues[name] !== calculatedValue) {
        handleChange(name, calculatedValue)
      }
    }
  }, [formValues, formula, handleChange, name])

  return (
    <FormContext.Consumer>
      {({hasError, firstError, handleChange}) => {
        return (
          <Field
            name={name}
            label={label}
            legend={legend}
            helptext={helptext}
            errorMessage={firstError(name)}
            className={className}
          >
            <TextInput
              name={name}
              type={type}
              disabled
              value={formValues[name]}
              onChange={(v) => {
                EventEmitter.dispatch('textinput.' + name, v)
                handleChange(name, v)
              }}
              hasError={hasError(name)}
              placeholder={placeholder}
            />
          </Field>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_MonthlySavings: FC<{
    name: string;
    label: string;
    className?: string;
}> = ({name, label, className}) => {
  const {values, handleChange, invalid, markInvalid} = React.useContext(FormContext)
  const value = getValue(values, name)

  React.useEffect(() => {
    if (invalid && value.final === value.sum) {
      markInvalid(false)
    }
    if (!invalid && value.final !== value.sum) {
      markInvalid(true)
    }
  }, [invalid, markInvalid, value])

  return (
    <div className={cx('hover:bg-gray-150 min-h-20 p-2 pb-4 rounded', className ? className : '')}>
      <div className="block leading-normal select-none text-gray-600">
        {label}
      </div>
      <MonthlySavings
        value={value !== '' ? value : null}
        onChange={value => handleChange(name, value)}
      />
    </div>
  )
}

const Form_Big_Advanced_Radio: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    required?: boolean;
    className?: string;
    options: AdvancedRadioValue[];
}> = ({name, label, legend, helptext, required, className, options}) => {
  return (
    <FormContext.Consumer>
      {({values, handleChange}) => {
        const colSize = options.reduce((acc, d) => {
          const val = d.span ? d.span : 1

          return (acc = acc + val)
        }, 0)

        return (
          <RadioBoxContainer
            type="big"
            label={label}
            legend={legend}
            helptext={helptext}
            required={required}
            colSize={colSize}
            className={className}
          >
            {options.map((item, idx: number) => {
              return (
                <BigRadioBox
                  key={`${item.value}${idx}`}
                  icon={item.icon ? item.icon : undefined}
                  title={item.title}
                  description={item.desc ? item.desc : ''}
                  span={item.span ? item.span : 1}
                  onClick={() => {
                    handleChange(name, item.value)
                    EventEmitter.dispatch('small_radio_box.' + name, item.value)
                  }}
                  selected={getValue(values, name) === item.value}
                />
              )
            })}
          </RadioBoxContainer>
        )
      }}
    </FormContext.Consumer>
  )
}

const Form_Small_Advanced_Radio: FC<{
    name: string;
    label: string;
    legend?: string;
    helptext?: string;
    required?: boolean;
    className?: string;
    options: AdvancedRadioValue[];
}> = ({name, label, legend, helptext, required, className, options}) => {
  return (
    <FormContext.Consumer>
      {({values, handleChange, firstError}) => {
        return (
          <RadioBoxContainer
            type="small"
            label={label}
            legend={legend}
            helptext={helptext}
            required={required}
            colSize={2}
            rowSize={Math.ceil(options.length / 2)}
            className={`${className} field-${name}`}
            errorMessage={firstError(name)}
          >
            {options.map((item, idx: number) => (
              <SmallRadioBox
                key={`${item.value}${idx}`}
                icon={item.icon ? item.icon : 'add'}
                title={item.title}
                color={item.color ? item.color : 'lightgray'}
                onClick={() => {
                  EventEmitter.dispatch('small_radio_box.' + name, item.value)
                  handleChange(name, item.value)
                }}
                selected={getValue(values, name) === item.value}
              />
            ))}
          </RadioBoxContainer>
        )
      }}
    </FormContext.Consumer>
  )
}

Form.Submit = Form_Submit
Form.TextInput = Form_TextInput
Form.Field = Form_UnifiedField
Form.CurrencyInput = Form_CurrencyInput
Form.Dropdown = Form_Dropdown
Form.Multiselect = Form_Dropdown_Multiselect
Form.DatePicker = Form_DatePicker
Form.TextArea = Form_TextArea
Form.Context = FormContext.Consumer
Form.Switch = {
  Single: Form_Switch_Single,
  Multiple: Form_Switch_Multiple
}
Form.Radio = {
  BigAdvanced: Form_Big_Advanced_Radio,
  SmallAdvanced: Form_Small_Advanced_Radio
}
//-------------- Other -----------------

Form.DependingDropdown = Form_DependingDropdown
Form.GeneratingDropdown = Form_GeneratingDropdown
Form.UserDropdown = Form_UserDropdown
Form.UserMultiselect = Form_UserMultiselect
Form.Formula = Form_Formula
Form.MonthlySavings = Form_MonthlySavings
Form.Wysiwyg = Form_Wysiwyg
Form.AttachmentUpload = Form_Attachment_Upload
