import {useState} from 'react'
import {http} from '../http'
import useDeepCompareEffect from 'use-deep-compare-effect'

type Element<T> = T extends (infer K)[] ? K : never;

export function useInfiniteRequest<K extends unknown[], T = Element<K>>(
  url: string,
  {
    params = {}
  }: {
        params?: Record<string, any>;
    } = {}
): [
    T[],
    {
        hasMore: boolean;
        loadMore: () => void;
    },
] {
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [state, setState] = useState<T[]>([])

  useDeepCompareEffect(() => {
    http.get<T[]>(url, {...params, page: String(page)}).then((response) => {
      if (response.meta) {
        setHasMore(response.meta.current_page < response.meta.last_page)
      }

      setState((state) => [...state, ...response.data])
    })
  }, [url, params, page])

  return [
    state,

    {
      hasMore,

      loadMore() {
        setPage(page + 1)
      }
    }
  ]
}
