import React, {FC} from 'react'
import {translate} from 'utils/translations'

export const DellSwpTutorial: FC<{}> = () => {

  return (
    <div>
      <h1 className="text-2xl mb-4">{`${translate('Welcome to your new project')}.`}</h1>
      <p>
        {translate(
          'Once Business Requirement Document section is filled, please continue by pressing progress to the next state.'
        )}
      </p>
    </div>
  )
}
