import React from 'react'
import {Message} from '../../components/Message'
import {Section, Variation} from './DesignSystem'

export function Messages() {
  return (
    <>
      <Section title="Message styles">
        <Variation hint="Success message">
          <div className="w-160">
            <Message.Success>Everything went according to the plan</Message.Success>
          </div>
        </Variation>

        <Variation hint="Warning message">
          <div className="w-160">
            <Message.Warning>Please, be cautious</Message.Warning>
          </div>
        </Variation>

        <Variation hint="Error message">
          <div className="w-160">
            <Message.Error>Not enough street credit, man</Message.Error>
          </div>
        </Variation>
      </Section>
    </>
  )
}
