import {http} from 'utils/http'
import {Document} from '@contractool/schema'

export function addVersion(document: Document, onUpdate: () => void) {
  http.post('/api/document/add_version/' + document.id, document).then((result: any) => {
    console.log('addVersion new document', result)
    window.open(result.data.edit_url, '_blank')
    onUpdate()
  })
}
