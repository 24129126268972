import React, {FC} from 'react'
import {Project} from '../../../../../_common/schema'
import {Confirmation} from 'components/Confirmation'
import {MenuItem} from 'components/Menu'
import {http} from 'utils/http'
import {translate} from 'utils/translations'
import {useHistory} from 'react-router-dom'

export const Delete: FC<{ project: Project; }> = ({project}) => {
  const history = useHistory()
  
  return (
    <Confirmation
      onConfirm={() => {
        http.delete(`api/projects/${project.id}`).then((data: any) => {
          console.log('data.data', data)
          history.push('/projects')
        })
      }}
      trigger={({onClick}) => (
        <MenuItem
          icon="delete"
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
        >
          {translate('Delete')}
        </MenuItem>
      )}
      heading={`${translate('Delete')}?`}
      buttonText={translate('Yes, delete')}
      color="red"
    >
      {translate('Are you sure you want to delete this project?')}
    </Confirmation>
  )
}
