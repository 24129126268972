import React from 'react'

import {Form, FormContext} from 'components/Form'
import {Icon} from 'components/Icon'
import {usePrevious} from 'hooks'
import ProjectFormContext from 'views/project/ProjectFormContext'

const ProjectTitle: React.FC<{className?: string}> = ({className}) => {
  const [editTitle, setEditTitle] = React.useState<boolean>(false)

  const {values: formValues, handleChange: handleFieldChange} = React.useContext(FormContext)
  const {fields, title} = formValues
  const {program_name: programName} = fields// to avoid null
  const {create, supplierTitle} = React.useContext(ProjectFormContext)

  const previousProgramName = usePrevious(programName)
  const previousSupplierTitle = usePrevious(supplierTitle)

  React.useEffect(() => {
    if (!create) return

    if (previousProgramName !== programName || previousSupplierTitle !== supplierTitle)
      handleFieldChange('title', (supplierTitle !== '' ? supplierTitle + ' ' : '') + 'NRE Agreement for ' + (programName || ''))
  }, [create, handleFieldChange, supplierTitle, programName, previousProgramName, previousSupplierTitle])

  return editTitle ? (
    <Form.TextInput
      name="title"
      label="Title"
      placeholder="Title"
      autoFocus
      required={true}
      className={className}
    />
  ) : (
    <div className={`${className}`}>
      <div className="text-gray-600 mb-1">Title</div>
      <div className="flex justify-between items-center bg-gray-100 rounded-lg py-3 pl-3 pr-6">
        <span
          className={`${title !== '' ? 'text-blue-500' : 'text-gray-500'}`}
        >
          {title !== ''
            ? title
            : 'Title is automatically generated'}
        </span>
        <span onClick={() => setEditTitle(true)} className="cursor-pointer">
          <Icon
            size={5}
            name="edit"
            className="text-blue-700 hover:text-blue-500"
          />
        </span>
      </div>
    </div>
  )
}

export default ProjectTitle
